.bx--text-area {
  @include input-field;

  &[readonly] {
    border: 0;
    padding: 0;
    height: 2rem;

    &:focus:not(:disabled) {
      outline: none;
    }
  }

  &__wrapper[data-invalid='true'] {
    .bx--text-area__invalid-icon {
      fill: $color-wildberry;
    }
  }
}

@import 'src/assets/scss/globals';

.bx--tabs--scrollable {
  margin-left: var(--global-content-horizontal-spacing);
  margin-right: var(--global-content-horizontal-spacing);
  outline: 0;

  &.bx--tabs--scrollable--modal {
    padding-left: $global-padding-large;
    margin-left: 0;
    margin-right: 0;

    .bx--tabs--scrollable__nav-link {
      span {
        display: flex;
        flex-flow: column;

        span {
          @include text-extra-extra-extra-small();
          text-transform: none;
        }
      }
    }
  }

  .bx--tabs--scrollable__nav {
    outline: 0;
  }

  .bx--tabs--scrollable__nav-item {
    min-height: 40px;

    .bx--tabs--scrollable__nav-link {
      @include text-small(bold);
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 0;
      padding-bottom: $global-padding-small;
      text-transform: uppercase;
      border: 0 !important;
      width: auto;
      padding-right: calc(#{$global-padding-large} * 3);
      color: $color-nightrider;
      position: relative;
      outline: none;

      &::after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 0;
        bottom: 0;
        left: 0;
        background-color: $color-dark-blue;
        transition: height 0.15s ease-in-out;
      }

      &:focus {
        color: $color-royal-blue;

        &::after {
          height: 3px;
          background: $color-dark-blue;
        }
      }

      .bx--tag {
        margin: 0;
        margin-left: 8px;
        max-height: 16px;
        min-height: 0;
        min-width: 0;
      }
    }

    &.bx--tabs--scrollable__nav-item--modal {
      .bx--tabs--scrollable__nav-link {
        padding-right: 1rem;
      }
    }

    &:hover {
      .bx--tabs--scrollable__nav-link {
        color: $color-dark-blue;

        &::after {
          height: 3px;
          background: $color-dark-blue;
        }
      }
    }

    &--disabled {
      .bx--tabs--scrollable__nav-link[aria-disabled='true'] {
        color: $color-suva-grey;

        &::after {
          display: none;
        }
      }
    }

    &--active {
      .bx--tabs--scrollable__nav-link {
        color: $color-nightrider;

        &::after {
          background: $color-lochmara;
          height: 3px;
        }
      }
    }
  }

  .bx--tab--overflow-nav-button:focus {
    outline-color: $color-royal-blue;
  }
}

.bx--tabs--border {
  width: 100%;
  height: 1px;
  background-color: $color-gainsboro;
}

@import '../../../assets/scss/globals';

.ResetPasswordDialog {
  .alignLeft {
    justify-content: flex-start !important;
  }

  .content {
    padding: $global-padding-medium;
    position: relative;

    > * {
      margin-bottom: $global-margin-medium;
    }

    &.SuccessView {
      p {
        @include text-small();
        color: $color-nightrider;
        margin: 1rem 0 2.5rem;
      }
    }
  }
}

@import 'src/assets/scss/globals';

.bx--pagination-nav {
  .bx--pagination-nav__list-item {
    .bx--pagination-nav__page {
      @include text-small;
      background-color: transparent;
      color: $color-nightrider;

      &.bx--pagination-nav__page--active::after {
        @include text-small(bold);
        background-color: $color-royal-blue;
      }

      &:hover {
        background-color: $color-gainsboro;
      }

      &:focus,
      &:active {
        outline: 2px solid $color-royal-blue;
      }
    }

    .bx--btn.bx--btn--ghost:not(:disabled) {
      &.bx--btn--icon-only .bx--btn__icon,
      &.bx--btn--icon-only .bx--btn__icon path {
        fill: $color-nightrider;
      }

      &:hover {
        background-color: $color-gainsboro;
      }

      &:focus:not(:disabled) {
        box-shadow: none;
        outline: 2px solid $color-royal-blue;
        outline-offset: -2px;
        transition: none;
      }
    }
  }
}

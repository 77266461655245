@import '../../../assets/scss/globals';

.Gallery {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &:focus-visible {
    box-shadow: inset 0 0 0 1px $color-royal-blue, inset 0 0 0 2px $color-white;
    outline: 2px solid $color-royal-blue;
    outline-offset: 2px;
  }

  .Track {
    height: 100%;

    div,
    ul,
    li {
      height: 100%;
    }

    li {
      display: flex;
      flex-direction: column;
    }
  }

  &__ControlsHidden {
    .GalleryControls {
      opacity: 0;
      transition: opacity 0.2s ease-out;
    }

    &:hover,
    &:focus-within {
      .GalleryControls {
        opacity: 1;
      }
    }
  }

  &__Fullscreen {
    overflow: visible;
    // spacing specific to control button size
    margin: 0 3.5rem 1.5rem;
    min-width: calc(100% - 7rem);
    width: calc(100% - 7rem);

    .GalleryControls {
      left: -3.5rem;
      width: calc(100% + 7rem);
    }

    .GalleryImage {
      object-fit: contain;
      max-height: 768px;
      min-height: 0;
      margin-bottom: $global-margin-extra-large;

      &Description {
        padding: $global-padding-medium 0;
        color: $color-white;
      }
    }

    [class*='glide__bullets'] {
      bottom: 0;
    }
  }
}

.GalleryControls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);

  > button {
    background-color: #0000004d !important;
    padding: $global-padding-extra-small;

    > svg[class*='bx--btn__icon'] {
      width: 2.25rem;
      height: 2.25rem;

      path {
        fill: $color-white !important;
      }
    }
  }
}

.OpenGalleryButton {
  position: absolute !important;
  bottom: $global-margin-medium;
  right: $global-margin-extra-large;
  padding-right: 3rem !important;
  padding-left: 1rem !important;

  > svg[class*='bx--btn__icon'] {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.GalleryImage {
  width: 100%;
  object-fit: cover;
  flex-grow: 1;
  max-height: 100%;

  &Description {
    @include text-small;
    color: $color-nightrider;
    padding: $global-padding-medium;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .OpenGalleryButton {
    display: none;
  }

  .Gallery {
    &__Fullscreen {
      margin: 0 0.5rem 1.5rem;
      padding-bottom: 2rem;
      width: calc(100% - 1rem);

      [class*='glide__bullets'] {
        bottom: $global-margin-medium;
      }

      .GalleryControls {
        left: 0;
        transform: translateY(-50%);
        width: 100%;

        > button {
          > svg[class*='bx--btn__icon'] {
            width: 2.5rem;
            height: 2.5rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet-small) {
  .Gallery {
    &__Fullscreen {
      padding-bottom: 1rem;

      [class*='glide__bullets'] {
        bottom: 2.3rem;
      }

      .GalleryControls {
        left: 0;
        bottom: 1rem;
        top: unset;
        transform: unset;
        width: 100%;
        background-color: #0000004d !important;

        > button {
          background-color: transparent !important;

          > svg[class*='bx--btn__icon'] {
            width: 2.5rem;
            height: 2.5rem;
          }
        }
      }
    }
  }
}

@import '../../../assets/scss/globals';

.Verification {
  @include text-small;
  display: flex;
  align-items: center;
  height: 100%;
  color: $color-empress;

  > svg {
    width: $font-size-medium;
    margin-right: $global-margin-small;
    fill: $color-empress;
  }

  &__verified {
    > svg {
      fill: $color-citrus;
    }
  }
}

@import 'src/assets/scss/globals';

.bx--tooltip {
  background-color: $color-nightrider;

  &__label {
    .bx--tooltip__trigger {
      margin-left: $global-margin-extra-small;

      svg {
        height: 1rem;
        fill: $color-lochmara;
      }

      &:hover,
      &:focus {
        outline: 2px solid $color-royal-blue;

        svg {
          fill: $color-royal-blue;
        }
      }
    }
  }
}

@import '../../../../assets/scss/globals';

.SaveBarModal {
  &.Activated {
    [class*='bx--modal-container'] [class*='bx--modal-content'] [class*='bx--modal-custom-footer'] {
      justify-content: space-between;
    }

    [class*='bx--btn--secondary'] {
      margin-left: 0;
    }
  }
}

.TagInactive {
  @include tag(inactive);
  min-width: 4rem;
}

.TagActive {
  @include tag;
  min-width: 4rem;
}

@import 'src/assets/scss/globals';

.bx--toggle-input {
  + .bx--toggle-input__label {
    > .bx--toggle__switch {
      color: $color-nightrider;
    }
  }

  &:checked {
    + .bx--toggle-input__label {
      > .bx--toggle__switch {
        &::before {
          background-color: $color-citrus;
        }

        .bx--toggle__check {
          fill: $color-citrus;
        }
      }
    }
  }

  &:focus,
  &:active {
    + .bx--toggle-input__label {
      > .bx--toggle__switch {
        &::before {
          box-shadow: 0 0 0 2px $color-royal-blue;
        }
      }
    }
  }

  &:disabled {
    + .bx--toggle-input__label {
      > .bx--toggle__switch {
        color: $color-suva-grey;

        &::after {
          background-color: #d6d6d6;
        }
      }
    }
  }
}

.bx--toggle-input--colored {
  .bx--toggle-input:not(:checked) {
    + .bx--toggle-input__label {
      > .bx--toggle__switch {
        &::before {
          background-color: $color-wildberry;
        }
      }
    }
  }
}

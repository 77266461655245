@import 'src/assets/scss/globals';

$overflow-menu-focus-outline-width: 3px;

.bx--overflow-menu {
  height: 2rem;
  width: 2rem;

  &:hover {
    background: $color-solitude-light;
  }

  &:focus {
    background: $color-solitude-light;
    outline: $overflow-menu-focus-outline-width solid $color-royal-blue;
  }
}

.bx--overflow-menu-options {
  &__btn {
    background: $color-white;
    padding: 0 $global-padding-small;

    > span {
      display: inline-flex;
      color: $color-charcoal;

      svg {
        height: $font-size-medium;
        margin-right: $global-padding-small;
        fill: $color-lochmara;
      }
    }

    &:hover {
      > span {
        color: $color-nightrider;

        svg {
          fill: $color-lochmara;
        }
      }
    }

    &:focus {
      background: $color-solitude-light;
      outline: $overflow-menu-focus-outline-width solid $color-royal-blue;
      outline-offset: calc(#{$overflow-menu-focus-outline-width} * -1);

      > span {
        color: $color-nightrider;
      }
    }
  }

  &__option {
    height: 2.125rem; // 32px + 2px border (optional)
  }

  &[data-floating-menu-direction='bottom']::after {
    width: 2rem;
  }

  &--open:focus {
    outline: $overflow-menu-focus-outline-width solid $color-royal-blue;
    outline-offset: calc(#{$overflow-menu-focus-outline-width} * -1);
  }
}

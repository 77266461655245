@import 'src/assets/scss/globals';

.bx--link {
  @include text-small;
  color: $color-lochmara;
  box-sizing: border-box;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: $color-royal-blue;

    &:visited {
      color: $color-royal-blue;
    }
  }

  &:focus {
    outline: 2px solid $color-royal-blue;
  }

  &:active {
    color: $color-dark-blue;
    border-bottom: 0;
    margin-bottom: 0;
  }

  &:visited {
    color: $color-lochmara;
  }
}

.bx--toast-notification:not(.bx--toast-notification--low-contrast) {
  .toast-link {
    .bx--link {
      color: $color-white;
      text-decoration: underline;
    }
  }
}

@import '../../../assets/scss/globals';

.SendMailDialog {
  [class*='bx--modal-container'],
  [class*='bx--modal-content'] {
    overflow: visible;
  }
}

.Meta {
  display: grid;
  grid-template-columns: min-content 1fr;
  padding: $global-padding-medium;
  align-items: center;
  gap: $global-margin-medium;
  position: relative;

  label {
    color: $color-empress;
  }

  input[readonly] {
    @include text-small;
    color: $color-nightrider;
    border: 0;
    outline: none;
  }

  .required {
    @include required-form-item-label;

    &:not([class*='bx--radio-button__label']):not([class*='bx--checkbox-label']) {
      &::after {
        margin-left: 0.125rem;
      }
    }
  }

  .RequiredToolTip {
    @include required-tooltip;
  }

}

@use 'src/components/controls/custom-styles/button' as button;
@import 'src/assets/scss/globals';

$number-input-arrow-size: 4px;
$number-input-border-width: 1px;
$number-stepper-button-border-width: 1px;
$number-input-outline-width: 2px;
$number-input-border-radius: 2px;
$number-stepper-button-width: 2.5rem;
$number-stepper-button-reserved-space: $number-stepper-button-width + 0.1rem;
$number-input-error-icon-width: 16px;

@mixin number-control-icon {
  content: '';
  position: absolute;
  left: 12px;
  top: unset;
  width: 0;
  height: 0;
  background-color: $color-white;
  border-left: $number-input-arrow-size solid transparent;
  border-right: $number-input-arrow-size solid transparent;
}

.bx--number {
  width: 100%;

  input[type='number'] {
    @include input-field;
    min-width: 8rem;
    text-align: center;

    // give the plus/minus buttons some space left and right
    padding-right: $number-stepper-button-reserved-space;
    padding-left: $number-stepper-button-reserved-space;

    &[data-invalid] ~ [class*='bx--number__controls'] [class*='bx--number__control-btn'][class*='up-icon']::after,
    &[data-invalid] ~ [class*='bx--number__controls'] [class*='bx--number__control-btn'][class*='up-icon']:focus::after,
    &[data-invalid]:focus ~ [class*='bx--number__controls'] [class*='bx--number__control-btn'][class*='up-icon']::after {
      background-color: transparent;
    }

    &[data-invalid='true'] {
      // when the input is invalid, an error icon is shown to the right of the input and to the left of the plus
      // button. Give it some space
      padding-right: calc(#{$number-stepper-button-reserved-space} + #{$number-input-error-icon-width});

      + svg.bx--number__invalid {
        // position the error icon
        right: $number-stepper-button-reserved-space;
      }
    }

    &:focus,
    &:active {
      &:not(:disabled) {
        ~ .bx--number__controls {
          border: $number-input-outline-width solid transparent;

          button {
            // when the input field is focused/active, it gets a 1px wider border (facing inward), therefore
            // the bx--number__controls container must be smaller also so that the buttons don't overlap the
            // inputs border. When the bx--number__controls gets 1 px smaller in height and width, the buttons
            // will still be vertically aligned, but horizontally they will jump. Therefore make the buttons
            // 2px smaller in width so that they stay at the same position
            width: calc(#{$number-stepper-button-width} - #{$number-input-outline-width});
            border-bottom-width: $number-stepper-button-border-width;
          }
        }
      }

      ~ .bx--number__controls {
        .bx--number__control-btn {
          // necessary to add styles here again to overwrite carbon styles on focus/active
          @include button.ghost-button-styling;

          &[type='button'] {
            // overwrite carbon outline on focus
            outline-color: transparent;
          }
        }
      }
    }
  }

  &__controls {
    // buttons are placed above the native input field. The button container has the same size as the input
    // with transparent borders that make sure that the buttons don't overlap the input border. On focus/active
    // the input has a 1px wider border (facing inward). In that case the buttons would overlap the input field,
    // therefore the invisible border is increased to 2px.
    border-radius: $number-input-border-radius;
    overflow: hidden;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    pointer-events: none; // container is above input field, therefore events should pass to the input field
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: $number-input-border-width solid transparent;
    transform: none;

    .bx--number__rule-divider {
      // no dividers necessary because buttons are split left and right to the input field
      display: none;
    }

    .bx--number__control-btn {
      @include button.ghost-button-styling;
      width: $number-stepper-button-width;
      height: 100%;
      pointer-events: auto; // buttons need to be clickable
      border-color: transparent;
      border-style: solid;
      border-width: 1px;

      &:focus {
        outline-color: transparent;
      }

      &::after,
      &::before {
        content: none;
      }
    }
  }
}

@import 'src/assets/scss/globals';

$checkbox-border-width: 2px;

.bx--checkbox {
  &:checked,
  &:indeterminate {
    + .bx--checkbox-label::before {
      background-color: $color-lochmara;
      border: 2px solid $color-lochmara;
    }
  }

  &:focus,
  &:focus:checked {
    + .bx--checkbox-label::before {
      box-shadow: 0 0 0 2px $color-royal-blue;
      outline: 0;
    }
  }

  &:disabled {
    + .bx--checkbox-label::before {
      border: 1px solid $color-dark-grey;
    }

    + .bx--checkbox-label {
      .bx--checkbox-label-text {
        color: $color-suva-grey;
      }
    }

    &:checked {
      + .bx--checkbox-label::before {
        background-color: $color-dark-grey;
      }
    }
  }
}

.bx--checkbox-label {
  &-text {
    @include text-small;
    color: $color-nightrider;
  }

  &::before {
    border: 2px solid $color-lochmara;
    border-radius: 2px;
  }
}

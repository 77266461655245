@import 'src/assets/scss/globals';

.bx--breadcrumb {
  min-height: 20px;
  display: flex;
  flex-wrap: wrap;

  .bx--breadcrumb-item {
    margin-right: 0;

    &:not(:first-child) {
      .bx--link {
        margin-left: $global-padding-semi-extra-small;
      }
    }

    .bx--link {
      @include text-extra-extra-small;
      color: $color-empress;
      box-sizing: border-box;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        color: $color-lochmara;
      }

      &:focus {
        outline: none;
        border-bottom: 2px solid $color-royal-blue;
        margin-bottom: -2px;
      }

      &:active {
        color: $color-empress;
        border-bottom: 0;
        margin-bottom: 0;
      }
    }

    &::after {
      @include text-small;
      color: $color-empress;
      margin-left: $global-padding-semi-extra-small;
    }

    &:last-child {
      .bx--link {
        color: $color-nightrider;

        &:hover {
          color: $color-lochmara;
        }
      }
    }
  }
}

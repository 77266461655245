@import '../../../assets/scss/globals';

.ComponentView {
  margin: 0 auto;

  [class*='filter-container'] {
    margin: $global-margin-medium 0;
  }

  > * {
    margin: $global-margin-medium $global-margin-large;
  }

  .Gallery {
    height: 25rem;
  }

  .MenuButton {
    @include text-semi-small(bold);
    padding-left: $global-padding-medium !important;
    padding-right: $global-padding-medium !important;
    color: $color-nightrider;
    text-transform: uppercase;

    > svg {
      height: 2.5rem;
      width: 2.5rem;
      margin-right: $global-margin-small;
    }
  }

  .FileUploadItemContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $global-padding-medium;

    @media screen and (max-width: $breakpoint-desktop) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $breakpoint-tablet) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

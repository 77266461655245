@import '../../../assets/scss/globals';

.MailSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  .ExpandButton {
    align-self: center;
    margin-bottom: $global-margin-small;

    &__expanded {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.MailSectionSkeleton {
  @include skeleton;
  height: 10rem;
}

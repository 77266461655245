@import '../../../assets/scss/globals';

.FavoriteButton {
  position: absolute;
  top: $global-margin-medium;
  left: $global-margin-medium;
  z-index: $z-index-favorite-button;

  &.isFavorite {
    > [class*='bx--btn'][class*='bx--btn--secondary'] {
      background-color: $color-lochmara;

      svg path {
        fill: $color-white;
      }
    }
  }

  > [class*='bx--btn'][class*='bx--btn--secondary'] {
    background-color: $color-white;
    min-width: 2.25rem;
    max-width: 2.25rem;
    min-height: 2.25rem;
    max-height: 2.25rem;
    border-radius: 50%;
    padding: calc(0.5rem - 1px);

    svg {
      width: 1.25rem;
      height: auto;

      path {
        fill: $color-lochmara;
      }
    }

    &:hover {
      background-color: $color-dark-blue;

      svg path {
        fill: $color-white;
      }
    }

    &:focus {
      outline: 0;
    }
  }
}

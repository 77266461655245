@import '../../../assets/scss/globals';

.TagInputWrapper {
  border: 1px solid $color-dark-grey;
  border-radius: 2px;
  background-color: $color-white;
  padding: 0 $global-padding-small;
  min-height: 2.5rem;
  outline-offset: -2px;

  display: flex;
  align-items: center;

  &:focus-within {
    outline: 2px solid $color-royal-blue;
  }

  &--disabled {
    border: 1px solid $color-white-smoke-darker;
    background-color: $color-white;
    color: $color-suva-grey;

    &:focus-within {
      outline: none;
    }
  }

  .TagContainer {
    .Tag {
      background-color: $color-white;
      border: 1px solid $color-dark-grey;
      color: $color-nightrider;

      > span {
        @include text-extra-small;
      }

      &__invalid {
        border-color: $color-wildberry;
      }
    }
  }

  .TagInput {
    @include text-small;
    @include placeholder;
    color: $color-nightrider;
    margin: 0 $global-margin-small;

    outline: none;
    border: 0;

    height: 100%;
    flex-grow: 1;
  }
}

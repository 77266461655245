@import '../../../assets/scss/globals';

.OverflowButton {
  @include text-small;
  color: $color-nightrider;
  width: auto;
  padding: calc(0.375rem - 3px) $global-padding-medium;

  .invisible {
    display: none;
  }

  &:hover {
    color: $color-dark-blue;

    [class*='bx--overflow-menu__icon'] {
      fill: $color-dark-blue;
    }
  }

  [class*='bx--overflow-menu__icon'] {
    margin-left: $global-margin-extra-small;
    fill: $color-lochmara;
  }
}

.OverflowButtonMenu {
  &[class*='bx--overflow-menu-options'] {
    width: 11.5rem;
  }

  [class*='bx--overflow-menu-options__btn'] {
    max-width: initial;
  }
}

@import '../../../assets/scss/globals';

.FilterDropDown {
  &Label {
    @include text-extra-small;
    color: $color-nightrider;
    margin-bottom: $global-margin-small;
    display: inline-block;
  }

  &Menu {
    position: relative;

    .FilterDropDownButton {
      outline: none;
      height: calc(2.5rem + 1px);
      border: 1px solid $color-dark-grey;
      background-color: $color-white;
      border-radius: 2px;
      width: 100%;
      max-height: 40px;
      text-align: left;
      padding: 0 $global-padding-extra-large 0 $global-padding-medium;
      cursor: pointer;

      > span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:focus {
        outline: 2px solid $color-royal-blue;
        outline-offset: -2px;
      }

      .FilterDropDownMenuIcon {
        position: absolute;
        top: 0;
        right: 1rem;
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;
        transition: transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);

        > svg {
          width: 1rem;
          height: 100%;
          fill: $color-nightrider;
        }
      }
    }

    .Dropdown {
      position: absolute;
      background-color: $color-white;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      z-index: 100;
      margin-top: 1px;

      &:focus {
        outline: 1px solid $color-royal-blue;
        outline-offset: -1px;
      }

      &:active {
        outline: none;
      }

      &__right {
        right: 0;
      }

      &__left {
        left: 0;
      }
    }

    &__open {
      .FilterDropDownMenuIcon {
        transform: rotate(180deg);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .FilterDropDown {
    &Label {
      @include text-extra-small;
      color: $color-nightrider;
      margin-bottom: $global-margin-small;
      display: inline-block;
    }

    &Menu {
      position: relative;

      .FilterDropDownButton {
        padding: 0 $global-padding-large 0 $global-padding-medium;

        .FilterDropDownMenuIcon {
          right: 0.5rem;
        }
      }
    }
  }
}

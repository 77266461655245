@import '../../../../../assets/scss/globals';

.SaveBarActions {
  white-space: nowrap;
}

.IconButton {
  padding-left: $global-padding-medium !important;
  padding-right: $global-padding-medium !important;
  margin-right: $global-margin-medium;

  > svg[class*='bx--btn__icon'] {
    width: $font-size-large;
    height: $font-size-large;
    position: initial;
  }

  &:not([class*='bx--btn--icon-only']) {
    > svg[class*='bx--btn__icon'] {
      margin-left: $global-margin-small;
    }
  }

  @media screen and (max-width: $breakpoint-desktop) {
    padding-left: $global-padding-small !important;
    padding-right: $global-padding-small !important;
    margin-right: $global-margin-large;

    > svg[class*='bx--btn__icon'] {
      width: 1.375rem;
      height: 1.375rem;
    }
  }

  @media screen and (max-width: $breakpoint-tablet) {
    margin-right: $global-margin-medium;
  }
}

@media screen and (max-width: $breakpoint-tablet-small) {
  .SaveBarActions {
    width: 100%;
    display: flex;

    .IconButton {
      margin-right: $global-margin-small;
    }

    > :last-child {
      margin-left: auto;
    }
  }
}

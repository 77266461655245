@import 'src/assets/scss/globals';

.bx--combo-box {
  border: 0;
  max-width: 50rem;

  .bx--list-box__field {
    @include dropdown;
    border: 0;
  }

  .bx--list-box__selection:focus {
    outline-color: $color-royal-blue;
  }

  &[data-invalid='true'] {
    .bx--text-input:not(:focus) {
      outline-color: $color-wildberry;
    }

    .bx--list-box__invalid-icon {
      fill: $color-wildberry;
    }

    + .bx--form-requirement {
      color: $color-wildberry;
    }
  }
}

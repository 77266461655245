@import '../../../../../../assets/scss/globals';

.ItemSection {
  > legend {
    @include text-small(bold);
    color: $color-nightrider;
    margin-bottom: $global-margin-small;

    + * > * {
      margin-top: 0;
      margin-bottom: $global-margin-large;

      &.RadioButtonGroup:not(legend) {
        margin-bottom: 0;
        align-self: center;
      }
    }
  }

  .TagsSection {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $global-margin-large;

    > * {
      width: 12rem;
    }

    [class*='bx--fieldset'] {
      flex-wrap: wrap;
      max-height: 16rem;
      margin-bottom: $global-margin-medium !important;

      @media screen and (max-width: $breakpoint-tablet-small) {
        max-height: none;
      }
    }

    .MiscTags:not(legend) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 0;

      > * {
        flex-grow: 0;
        width: 12rem;
      }
    }
  }
}

.ItemRange {
  display: flex;
  flex-flow: row;

  &Dash {
    display: flex;
    justify-content: center;
    min-width: 2rem;
    color: $color-suva-grey;
    padding-top: 2.25rem;
  }
}

.ItemGroup {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;

  p {
    @include text-extra-small;
    color: $color-nightrider;
    margin-top: $global-margin-small;
  }

  > * {
    margin-top: $global-margin-large;
    margin-right: 2.5rem;
    width: 18rem;
    max-width: 18rem;
  }

  &.ItemGroupSmall {
    > * {
      width: 7.5rem;
      max-width: 7.5rem;
    }

    .Checkbox {
      margin-top: $global-margin-small;
      margin-bottom: $global-margin-large;
    }
  }

  &.ItemGroupLarge {
    > * {
      width: 100%;
      max-width: calc(100% - 2rem);
    }
  }

  [class*='bx--number'] {
    max-width: 8rem;
  }

  [class*='bx--date-picker__input '] {
    max-width: 7.5rem;
  }

  [for*='squareMeterTo'],
  [for*='totalRentTo'] {
    visibility: hidden;
    white-space: nowrap;
  }

  [for*='deposit'] + [class*='bx--number__input-wrapper'] {
    max-width: 18rem;
  }
}

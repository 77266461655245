@import '../../../assets/scss/globals';

.ApprovalState {
  @include text-small;
  color: $color-nightrider;
  display: flex;
  align-items: center;

  svg {
    width: 1.25rem;
    margin-right: $global-margin-extra-small;
  }

  &.approved {
    svg {
      fill: $color-citrus;
    }
  }

  &.blocked {
    svg {
      fill: $color-wildberry;
    }
  }

  &.inactivated {
    svg {
      background-color: $color-empress;
      fill: $color-white;
      border-radius: 50%;
      width: 1rem;
    }
  }
}

@import '../../../../../assets/scss/globals';

$field-spacing: 0.875rem;
$listings-view-nav-width: 12rem;
$line-height: 1rem;

.Content {
  padding: $global-padding-large var(--global-content-horizontal-spacing);

  nav {
    width: $listings-view-nav-width;
    max-width: $listings-view-nav-width;
  }

  [class*='bx--form-item'] [class*='bx--label'] {
    margin-bottom: 0;
  }
}

.Contact {
  display: grid;
  grid-template-columns: 1fr 2fr;

  .ContactData {
    display: flex;
    flex-flow: column;

    h3 {
      @include input-label;
      margin-bottom: $field-spacing;
    }

    p {
      @include text-small();
      color: $color-nightrider;
      line-height: $line-height;
    }
  }

  .contactEmail {
    margin-top: $field-spacing;
  }

  [class*='bx--form-item']:not(:first-child) {
    margin-top: $field-spacing;
  }
}

.SearchInformation {
  h3 {
    @include text-small(bold);
    color: $color-nightrider;
    margin: 0 0 $global-margin-medium 0;
  }

  .Group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    gap: 1rem;
    margin-bottom: $global-margin-extra-large;
  }

  ul {
    margin-bottom: $global-margin-extra-large;

    li {
      @include text-small();
      line-height: $line-height;
      list-style: disc;
      margin-left: $global-margin-medium;
    }
  }

  .textOverflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.Responsibility {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: $global-margin-extra-large;
}

.SectionInfo {
  @include text-small(bold);
  color: $color-nightrider;
  margin-bottom: $global-margin-small;
}

@import '../../../assets/scss/globals';

.FilterContainer {
  position: relative;
  background-color: $color-solitude-light;
  margin-bottom: $global-margin-medium;
  text-align: left;

  [class*='global-content-wrapper-block'] {
    padding: $global-padding-large var(--global-content-horizontal-spacing) $global-padding-medium;
  }

  h1 {
    @include text-semi-large;
    margin-bottom: $global-margin-large;
    color: $color-nightrider;
  }

  .content {
    display: flex;
    flex-flow: row;
    align-items: flex-end;

    > *:not(button) {
      margin-right: $global-margin-medium;
      min-width: 5rem;

      &:last-child {
        margin-right: 0;
      }
    }

    @media screen and (max-width: $breakpoint-desktop) {
      > *:not(button) {
        margin-right: $global-margin-small;
      }
    }
  }

  .subContent {
    margin-top: $global-margin-small;
    display: flex;
    justify-content: flex-end;

    .FilterTagContainer {
      flex: 1 0 auto;
      width: 80%;

      [class*='bx--tag--filter'] {
        @include white-tag();

        button {
          &:hover {
            background-color: $color-dark-blue;

            svg path {
              fill: $color-white;
            }
          }

          &:focus {
            box-shadow: 0 0 0 2px $color-royal-blue;
          }
        }
      }
    }

    .reset {
      text-align: end;
      flex: 0 0 auto !important;

      > button {
        margin-right: -1rem;
      }
    }
  }
}

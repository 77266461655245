@import 'src/assets/scss/globals';

.SideBar {
  width: 23rem;
  height: 100vh;
  position: fixed;
  transform: translateX(100%);
  background-color: $color-white;
  filter: drop-shadow(0 0 2px $color-charcoal);
  margin: 0;
  right: 0;
  top: 0;
  z-index: $z-index-sidebar;
  transition: transform 0.15s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto;


  @media screen and (max-width: $breakpoint-tablet-small) {
    width: 100vw;
  }

  &__open {
    transform: translateX(0);
  }

  &Background {
    z-index: 100;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;

    &__open {
      pointer-events: all;
      background: rgba($color: $color-black, $alpha: 0.53);
    }
  }

  &Heading {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    padding: $global-padding-large $global-padding-large 0 $global-padding-large;
    background-color: $color-white;
    z-index: 1;

    h2 {
      @include text-medium(bold);
      color: $color-nightrider;
    }

    button {
      margin-top: -$global-margin-small;

      > svg[class*='bx--btn__icon'] path {
        fill: $color-nightrider !important;
      }
    }
  }

  &Content {
    flex-grow: 1;
    padding: $global-padding-large;
  }

  &Actions {
    padding: $global-padding-medium $global-padding-large $global-padding-large $global-padding-large;
    background-color: $color-solitude;
  }
}

@import '../../../assets/scss/globals';

.PrivacyPolicy {
  @extend %global-content-padding;
  @include plain-html-style;
  position: relative;
  max-width: 49rem;

  [class*='leadText'] {
    @include text-semi-small;
    color: $color-empress;
  }

  .Tracking {
    h2 {
      padding-top: $global-margin-large;
    }
  }

  .InfoBox {
    background-color: transparent;
    border: 1px solid $color-dark-grey;
    margin-bottom: -1px;
    padding: $global-padding-medium;
    margin-top: $global-margin-extra-extra-large;
    position: relative;

    h2 {
      @include text-small(bold);
      color: $color-nightrider;

      &::before {
        background-color: $color-dark-grey;
        color: $color-dark-grey;
        content: '.';
        display: block;
        height: 1.5rem;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0.75rem;
        width: 3px;
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  h2 {
    @include text-semi-extra-large(bold);
    color: $color-charcoal;
    margin-bottom: 1.25rem;
  }

  h3 {
    @include text-semi-large(bold);
    color: $color-charcoal;
    margin: 2.5rem 0 2.5rem 1.625rem;
    list-style: upper-alpha;
    display: list-item;
  }

  h4 {
    @include text-semi-small(bold);
    color: $color-charcoal;
    border-bottom: 1px solid $color-dark-grey;
    padding-bottom: 3px;
    text-transform: uppercase;
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
  }
}

@import 'src/assets/scss/globals';

.bx--inline-loading {
  width: auto;

  .bx--inline-loading__text {
    @include text-small;
    color: $color-nightrider;
  }

  .bx--loading__stroke {
    stroke: $color-lochmara;
  }

  .bx--loading__svg {
    stroke: transparent;
  }
}

@import '../../../assets/scss/globals';

.TextAreaCounter {
  position: relative;

  .counter {
    @include input-label;
    position: absolute;
    top: 0;
    right: 0;

    &.disabled {
      color: $color-suva-grey;
      cursor: default;
    }
  }

  &__readonly {
    textarea {
      resize: none;
    }

    .counter {
      display: none;
    }
  }
}

@import 'src/assets/scss/globals';

.bx--data-table {
  thead {
    background-color: transparent;

    tr {
      border-bottom: 2px solid $color-gainsboro;
    }
  }

  th {
    @include text-small(bold);
    background-color: transparent;
    color: $color-charcoal;
    padding: $global-padding-medium $global-padding-small;
  }

  .bx--table-header-label {
    @include text-small(bold);
    color: $color-charcoal;
    padding: 0;
  }

  tbody {
    background-color: transparent;

    tr {
      background-color: transparent;
      border-bottom: 1px solid $color-gainsboro;

      &:hover {
        background-color: transparent;

        td {
          background-color: transparent;
          border: 0;
        }
      }

      td {
        background-color: transparent;
        color: $color-nightrider;
        padding: 0 $global-padding-small;
        border: 0;

        &:last-child {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 3rem;
        }
      }
    }
  }
}

@import '../../../assets/scss/globals';

.RegisterDialog {
  .alignLeft {
    justify-content: flex-start !important;
  }

  h2 {
    text-transform: uppercase;
  }

  h3:not(:empty) {
    @include text-small(bold);
    text-transform: uppercase;
    margin-bottom: $global-margin-small;
    margin-top: $global-margin-medium;
  }

  [class*='bx--inline-notification '] {
    margin: 0 0 $global-padding-medium;
  }

  .content {
    padding: $global-padding-medium;
    position: relative;

    &.SuccessView {
      p {
        @include text-small();
        color: $color-nightrider;
        margin: 1rem 0 2.5rem;
      }
    }

    .SubGrid {
      display: flex;

      [class*='bx--dropdown__wrapper'] {
        min-width: 7.75rem;
      }

      > div {
        padding-right: 1rem;
        flex: 1 1 auto;
        min-width: 7rem;

        &:last-child {
          padding-right: 0;
        }
      }

      &.street {
        [class*='bx--form-item']:last-child {
          flex: 0 1 20%;
        }
      }

      &.city {
        [class*='bx--form-item']:first-child {
          flex: 0 1 33%;
        }
      }
    }

    [class*='bx--checkbox-label-text'],
    [class*='bx--link'] {
      @include text-extra-extra-extra-small;
    }

    [class*='bx--checkbox-label'] {
      align-items: center;
      line-height: 0;
    }

    .requiredMarker {
      @include text-small;
      color: $color-lochmara;
    }

    label,
    legend {
      @include required-form-item-label;
    }

    .RequiredToolTip {
      @include required-tooltip;
    }

    [class*='bx--form-item'] {
      margin-bottom: $global-margin-medium;
    }

    > p:not(.Explanation) {
      @include text-extra-extra-extra-small();
      color: $color-nightrider;
      margin: 0 0 0.5rem;

      [class*='bx--link'] {
        @include text-extra-small(bold);
        cursor: pointer;
      }
    }

    > .Explanation {
      @include text-small();
      color: $color-nightrider;
      margin-bottom: $global-margin-medium;
    }
  }

  .RegistrationStepLabel:first-child {
    @include text-small();
    color: $color-nightrider;
    align-items: center;
    display: flex;
  }
}

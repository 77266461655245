@import '../../../assets/scss/globals';

.LegalDetails {
  @extend %global-content-padding;
  @include plain-html-style;
  max-width: 49rem;

  h2 {
    @include text-semi-extra-large(bold);
    color: $color-charcoal;
    margin-bottom: 1.25rem;
  }

  h3 {
    @include text-semi-small(bold);
    color: $color-charcoal;
    border-bottom: 1px solid $color-dark-grey;
    padding-bottom: 3px;
    text-transform: uppercase;
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
  }
}

@import '../../../assets/scss/globals';

.Login {
  [class*='bx--modal-custom-content'] {
    display: flex;
    flex-flow: column;
  }

  p:not([class*='bx--inline-notification__title']):not(.landlordExplanation) {
    font-size: $font-size-extra-extra-extra-small !important;
  }

  a {
    @include text-smaller();
    cursor: pointer;
  }

  .RegisterLink {
    margin-left: 0.25rem;
  }

  .passwordLost {
    font-size: $font-size-extra-extra-extra-small !important;
    min-height: auto;
    align-self: flex-end;
    padding: 0.25rem !important;
    color: $color-nightrider;
    margin-top: -0.75rem;

    svg {
      fill: $color-lochmara;
      width: 1rem;
      height: 1rem;
    }
  }
}

.alignLeft {
  justify-content: flex-start !important;
}

@import '../../../assets/scss/globals';

.FileUploadItem {
  width: 100%;
  background-color: $color-solitude-light;
  padding: $global-padding-medium 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: $global-padding-medium;

  &__error {
    border: 2px solid $color-wildberry;
  }

  &Header {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
  }

  > * + * {
    margin-top: $global-margin-medium;
  }

  &Name {
    @include text-small;
    color: $color-nightrider;
    flex: 1 0 0;
    max-height: 2 * $font-size-medium;
    overflow: hidden;
    margin-right: $global-margin-medium;
    line-height: $font-size-medium;
  }

  .DeleteButton {
    svg path {
      fill: $color-nightrider !important;
    }
  }

  .StatusIndicator {
    height: 2rem;
    width: 2rem;
    padding: $global-padding-extra-small;

    path {
      fill: $color-lochmara;
    }

    &__error {
      path {
        fill: $color-wildberry;
      }
    }
  }

  .ImageContainer {
    width: 100%;
    position: relative;
    background-color: $color-white;

    .ImageWrapper {
      position: relative;
      height: 100%;
      padding-bottom: 56.25%;

      > img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      .RotateButtons {
        position: absolute;
        display: flex;
        bottom: $global-margin-small;
        right: $global-margin-small;
        flex-direction: column;
        gap: $global-margin-small;

        .RotationButton {
          background: rgba(22, 22, 22, 0.65);
          padding: $global-padding-small;

          > svg {
            * {
              fill: $color-white;
            }
          }
        }
      }
    }

    .StatusDisclaimer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      max-width: 50%;

      svg {
        height: 48px;
        margin-bottom: $global-margin-small;

        * {
          fill: $color-dark-grey;
        }
      }

      .Error {
        * {
          fill: $color-wildberry;
        }
      }

      span {
        @include text-small();
        color: $color-nightrider;
        text-align: center;
        line-height: 1.2;
      }
    }
  }
}

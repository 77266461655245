@import '../../../../assets/scss/globals';

.SearchRequest {
  padding-top: $global-padding-medium;

  .FormGroup {
    padding: 0 $global-padding-medium;

    > * {
      padding: $global-padding-extra-small $global-padding-small;
    }

    > legend {
      @include text-semi-large;
      color: $color-nightrider;
      border-bottom: 3px solid $color-dark-grey;
      margin-bottom: $global-margin-large;
      width: 100%;
    }

    [class*='bx--fieldset'] {
      margin-bottom: 0;
    }

    .TagsSection [class*='bx--fieldset'] {
      margin-bottom: $global-margin-large;
    }
  }
}

.SubmitBar {
  height: 5rem;
  background-color: $color-solitude-light;
  display: flex;
  padding: $global-padding-medium $global-padding-extra-large;
  justify-content: space-between;
  margin-bottom: $global-margin-extra-small;
}

.SearchRequestSuccess {;
  padding: $global-padding-medium;

  p {
    @include text-small();
    color: $color-nightrider;
    margin-bottom: $global-margin-medium;

    &:first-child {
      @include text-small(bold);
    }

    &:nth-child(2) {
      margin-bottom: $global-margin-extra-large;
    }
  }
}

.SearchRequestError {
  padding: $global-padding-medium;
  max-width: 50rem;
}

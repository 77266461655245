@import '../../../../../assets/scss/globals';

$listings-view-nav-width: 12rem;
$listings-view-nav-width-tablet: 10rem;
$listing-tags-icons-strikethrough-thickness: 3px;
$listing-tags-icons-strikethrough-length: 125%;
$listing-tags-icons-strikethrough-rotation: 135deg;
$listing-tags-breakpoint: 600px;
$listing-env-breakpoint: 920px;
$listing-two-column-section-border: 1px solid $color-dark-grey;

.Content {
  padding: 8rem var(--global-content-horizontal-spacing) $global-padding-large var(--global-content-horizontal-spacing);

  nav {
    width: $listings-view-nav-width;
    max-width: $listings-view-nav-width;

    @media screen and (max-width: $breakpoint-desktop) {
      width: $listings-view-nav-width-tablet;
      max-width: $listings-view-nav-width-tablet;
      margin-right: $global-margin-medium;
    }
  }
}

.FixedInfoPanel {
  display: flex;
  justify-content: center;
  transform: translateY(-25%);
  position: absolute;
  left: 0;
  right: 0;

  .InfoPanelContent {
    margin-top: 0;
    background: $color-white;
    padding: $global-margin-large;
  }
}

section.ListingSectionCard {
  @include card-drop-shadow;
  padding: $global-padding-large $global-padding-large $global-padding-extra-large $global-padding-large;

  h2 {
    @include text-medium(bold);
    border: 0;
    margin-bottom: $global-margin-medium;
  }

  > *:not(:first-child) {
    margin: 0 $global-margin-small;
  }

  .FurtherEquipment {
    margin-bottom: $global-margin-medium;
  }

  .Map {
    margin-top: $global-margin-medium;
    margin-left: -$global-margin-large;
    margin-right: -$global-margin-large;
    margin-bottom: -$global-margin-extra-large;
  }

  p {
    @include text-small();
    color: $color-nightrider;
  }
}

.ContactCard {
  background: $color-solitude-light;
}

.GalleryContainer {
  height: 24rem;
  margin: auto;
  position: relative;
  max-width: $global-max-width-raw;

  .PreviewDisclaimer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(22, 22, 22, 0.85);
    color: $color-white;
    padding: $global-padding-medium $global-padding-large;
    max-width: 30%;
    display: block;
    text-align: center;
    line-height: 1.1;

    svg {
      fill: $color-white;
      width: 2rem;
      height: 2rem;
      display: block;
      margin-bottom: $global-margin-small;
      margin-left: auto;
      margin-right: auto;
    }

    > span {
      display: inline-block;
    }

    &Title {
      @include text-semi-small(bold);
    }

    &Subtitle {
      @include text-smaller;
      margin-top: $global-margin-extra-small;
      line-height: normal;
    }
  }

  .GalleryPlaceHolder {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.Gallery {
  &FullScreen {
    max-width: max-content;
  }
}

.GalleryModal {
  &[class*='is-visible'] {
    background-color: rgba(22, 22, 22, 0.9);
  }

  [class*='bx--modal-container'] {
    background: transparent;

    button[class*='bx--modal-close'] {
      background: transparent;
      padding: $global-padding-semi-extra-small;

      &:hover {
        background: transparent;
      }

      svg {
        fill: $color-white;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &Header {
    h2 {
      @include text-large;
      color: $color-white;
      margin-left: 2.5rem;
    }
  }

  &Body[class*='bx--modal-content'] {
    border-top: 0;
  }
}

.TwoColumnSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $global-margin-medium;

  > :last-child {
    border-left: $listing-two-column-section-border;
    padding-left: $global-padding-large;
  }

  > * {
    padding-top: $global-padding-small;
  }

  .PropertyRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    margin-bottom: $global-margin-large;

    > :first-child {
      @include text-extra-small;
      color: $color-charcoal;
    }

    > * {
      @include text-small;
      color: $color-nightrider;
    }
  }
}

.Address {
  @include text-small;
  color: $color-nightrider;
}

.EnvironmentalInfo {
  h3 {
    @include text-small;
    color: $color-nightrider;
    margin-bottom: $global-margin-medium;
    margin-top: $global-margin-medium;

    &:first-child {
      margin-top: 3rem;
    }
  }

  p svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.875rem;
  }

  p > svg {
    fill: $color-lochmara;
  }

  .EnvInfoContainer {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    max-height: 8rem;

    @media screen and (max-width: $listing-env-breakpoint) {
      max-height: none;
    }
  }

  .TumContainer {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    max-height: 4rem;

    @media screen and (max-width: $listing-env-breakpoint) {
      max-height: none;
    }

    p:first-child:not(:only-child) {
      margin-bottom: $global-padding-extra-large;
    }
  }

  .EnvInfo {
    display: flex;
    align-items: center;
    margin-bottom: $global-margin-small;

    .EnvInfoTitle {
      display: flex;
      flex-flow: column;
      width: 10rem;

      @media screen and (max-width: $breakpoint-tablet-small) {
        width: 7.5rem;
      }

      @media screen and (max-width: $breakpoint-phone) {
        width: 6rem;
      }

      .EnvInfoName {
        @include text-small(bold);
        color: $color-nightrider;

        @media screen and (max-width: $breakpoint-tablet-small) {
          @include text-extra-small(bold);
        }
      }

      .EnvInfoAddress {
        @include text-extra-small();
        color: $color-nightrider;

        @media screen and (max-width: $breakpoint-tablet-small) {
          @include text-extra-extra-small;
        }
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .EnvInfoDuration {
      display: flex;
      align-items: center;
      margin-left: $global-margin-medium;

      span {
        @include text-extra-small();
        color: $color-nightrider;
        white-space: nowrap;
      }
    }
  }
}

.RentOverview {
  margin-bottom: $global-margin-small;
}

.OneTimeCostsOverview {
  > h3 {
    @include text-small(bold);
    color: $color-nightrider;
    display: flex;
    align-items: center;
    margin-bottom: $global-margin-medium;
  }

  .PropertyRow {
    align-items: flex-end;
  }
}

.TargetGroups {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  li {
    display: flex;
    align-items: center;
    margin-bottom: $global-margin-small;
    margin-top: $global-margin-small;
    margin-right: $global-margin-medium;

    svg {
      margin-right: $global-margin-medium;

      path + path {
        fill: $color-nightrider;
      }
    }

    span {
      @include text-small();
      color: $color-nightrider;
    }
  }
}

.TagsList {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;

  .ListingTag {
    @include text-small;
    display: flex;
    align-items: center;
    color: $color-nightrider;
    margin-top: $global-margin-medium;

    .visuallyHidden {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }

    > svg {
      flex: 0 0 auto;
      width: 2rem;
      height: 2rem;
      margin-right: $global-margin-small;
      fill: $color-nightrider;
    }

    &Disabled {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        background: $color-suva-grey;
        width: $listing-tags-icons-strikethrough-thickness;
        height: $listing-tags-icons-strikethrough-length;
        left: calc(1rem - 2px);
        transform: rotate($listing-tags-icons-strikethrough-rotation);
      }

      &::after {
        position: absolute;
        content: '';
        background: $color-white;
        width: 2px;
        height: calc(#{$listing-tags-icons-strikethrough-length} + 15%);
        left: calc(1rem + 1px);
        transform: rotate($listing-tags-icons-strikethrough-rotation);
      }

      > svg {
        position: relative;
        fill: $color-suva-grey;
      }
    }
  }

  .MiscTags {
    max-height: 24rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .ListingTag {
      max-width: 50%;
    }
  }
}

.TagCategoryLabel {
  @include text-extra-small;
  color: $color-charcoal;
}

.ContactDataHiddenNotice {
  display: flex;

  > svg {
    height: 1.5rem;
    width: 1.5rem;
    flex: 0 0 auto;

    margin-right: $global-margin-small;
  }

  p {
    margin-top: 0.4rem; // align with icon
    margin-bottom: $global-margin-medium;
  }
}

.ContactDataHeading {
  @include text-small;
  color: $color-nightrider;
  display: flex;
  align-items: center;

  svg {
    height: 2rem;
    fill: $color-nightrider;
    margin-right: $global-margin-small;
  }

  .VerifiedStatusNotice {
    @include text-extra-small;
    color: $color-charcoal;
  }
}

.IconButton {
  padding-left: $global-padding-medium !important;
  padding-right: $global-padding-medium !important;
  margin-right: $global-margin-medium;

  > svg[class*='bx--btn__icon'] {
    width: $font-size-large;
    height: $font-size-large;
    position: initial;
    margin-left: $global-margin-small;
  }
}

.ContactDataRow {
  @include text-small;
  display: flex;
  align-items: center;

  margin-bottom: $global-margin-small;

  svg {
    fill: $color-lochmara;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: $global-margin-small;
  }
}

.ContactButton {
  margin-top: $global-margin-small;
}

.ReportContact {
  &__Spacing {
    margin-left: 2.5rem; // align with contact type
    margin-top: $global-margin-large;
  }

  .ReportContactNotice {
    @include text-extra-small;
  }

  .ReportButton {
    @include text-extra-small(bold);
    margin-left: -$global-margin-medium;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .Content {
    padding: 0 var(--global-content-horizontal-spacing) $global-padding-large var(--global-content-horizontal-spacing);
  }

  .TargetGroups {
    grid-template-columns: 1fr 1fr;

    li svg {
      margin-right: $global-margin-small;
    }
  }

  .GalleryContainer {
    height: 21rem;
  }

  section.ListingSectionCard {
    padding: $global-padding-medium;

    h2 {
      @include text-semi-small(bold);
      margin-bottom: $global-margin-small;
    }

    .Map {
      margin-top: $global-margin-medium;
      margin-left: -$global-padding-medium;
      margin-right: -$global-padding-medium;
      margin-bottom: -$global-padding-medium;
    }
  }

  .TwoColumnSection {
    display: grid;
    gap: $global-margin-medium;

    > :last-child {
      padding-left: $global-padding-medium;
    }

    > * {
      padding-top: 0;
      padding-bottom: $global-padding-small;
    }

    .PropertyRow {
      margin-top: $global-margin-medium;
      margin-bottom: 0;
    }
  }

  .OneTimeCostsOverview {
    .PropertyRow {
      margin-bottom: $global-margin-medium;
    }
  }

  .TagsList {
    .ListingTag {
      @include text-extra-small;
      margin-top: $global-margin-small;

      > svg {
        width: 1.75rem;
        height: 1.75rem;
        margin-right: $global-margin-small;
      }

      &Disabled {
        &::before {
          left: calc(0.875rem - 2px);
        }

        &::after {
          left: calc(0.875rem + 1px);
        }
      }
    }

    .MiscTags {
      max-height: 18rem;
    }
  }

  .GalleryContainer {
    .PreviewDisclaimer {
      max-width: 35%;

      svg {
        width: 1.75rem;
        height: 1.75rem;
      }

      &Title {
        @include text-small(bold);
      }

      &Subtitle {
        @include text-smaller;
      }
    }
  }

  .ContactCard {
    > div > h2 {
      margin-bottom: $global-margin-medium;
    }
  }

  .ReportContact {
    margin-top: $global-margin-medium;
  }

  .FixedInfoPanel {
    position: relative;
    transform: none;
  }

  .GalleryModalHeader {
    padding-left: 0.5rem;

    h2 {
      @include text-semi-small;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet-small) {
  .Content {
    padding: 0 var(--global-content-horizontal-spacing) $global-margin-medium var(--global-content-horizontal-spacing);
  }

  .GalleryContainer {
    height: 16rem;
  }

  .TargetGroups {
    grid-template-columns: 1fr;

    li {
      svg {
        width: 1.25rem;
        height: 1.25rem;
      }

      span {
        @include text-extra-small;
      }
    }
  }

  section.ListingSectionCard {
    padding: $global-padding-medium;

    h2 {
      @include text-small(bold);
      margin-bottom: $global-margin-extra-small;
    }

    .Address {
      @include text-extra-small;
    }

    .ContactDataHiddenNotice {
      padding-bottom: $global-padding-medium;

      p {
        @include text-extra-small;
        margin-top: 0.4rem; // align with icon
        margin-bottom: $global-margin-small;
      }
    }

    .ContactDataHeading {
      @include text-extra-small;
      padding-top: $global-margin-small;
      border-top: $listing-two-column-section-border;

      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }

    .ContactDataRow {
      @include text-extra-small;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    .ContactButton {
      margin-top: 0;
    }
  }

  .TwoColumnSection {
    &__WrapMobile {
      display: block;

      > :last-child {
        border-left: 0;
        padding-left: 0;
      }
    }

    .RentOverview {
      max-width: none;
    }

    .OneTimeCostsOverview {
      padding: 0;

      h3 {
        @include text-extra-small(bold);
        margin-bottom: $global-margin-small;
        margin-top: $global-margin-small;
      }

      .PropertyRow {
        grid-template-columns: 1fr 1fr;
        margin: $global-margin-small 0;

        > :last-child {
          justify-self: flex-end;
        }
      }
    }

    .PropertyRow {
      grid-template-columns: 1fr;
      gap: $global-margin-small;

      > * {
        @include text-extra-small;

        &:first-child {
          @include text-extra-extra-small;
        }
      }
    }
  }

  .TagsList {
    .ListingTag {
      @include text-extra-extra-small;
      margin-top: $global-margin-medium;

      > svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: $global-margin-extra-small;
      }
    }

    .MiscTags {
      max-height: 20rem;
    }
  }
}

@media screen and (max-width: $breakpoint-phone) {
  .GalleryContainer {
    height: 16rem;
  }
}

@media screen and (max-width: $listing-tags-breakpoint) {
  .TagsList {
    grid-template-columns: 1fr 1fr;
    gap: $global-margin-medium;

    > li:last-child {
      grid-column: 1 / span 2;
    }
  }
}

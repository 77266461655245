.DraftEditor-root {
  padding: $global-padding-medium;
  font-size: 14px;
  overflow-y: auto;
  max-height: calc(85vh - 330px);

  .public-DraftEditorPlaceholder-root {
    @include text-small;
    position: absolute;
    color: $color-suva-grey;
  }

  &:focus-within {
    .public-DraftEditorPlaceholder-root {
      display: none;
    }
  }

  .public-DraftEditor-content {
    min-height: 20rem;
  }

  ul {
    margin: 1rem;
    list-style: disc;
  }

  ol {
    margin: 1rem;
    list-style: decimal;
  }

  .align-right div {
    text-align: right;
  }

  .align-center div {
    text-align: center;
  }

  .align-left div {
    text-align: left;
  }

  a {
    text-decoration: underline;
    color: $color-lochmara;
  }
}

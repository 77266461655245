@import '../../../assets/scss/globals';

.Greeting {
  background: $color-solitude-light;
  height: 4.5rem;
  display: flex;

  [class*='global-content-wrapper'] {
    padding: 0 var(--global-content-horizontal-spacing);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    h1 {
      @include text-semi-large(bold);
      color: $color-nightrider;
    }
  }
}

.CreateNewButton {
  padding-left: $global-padding-medium !important;
  padding-right: 2.5rem !important;
}

.AccountContent {
  padding: $global-padding-medium var(--global-content-horizontal-spacing);
}

@media screen and (max-width: $breakpoint-desktop) {
  .Greeting {
    height: 4rem;

    [class*='global-content-wrapper'] {
      h1 {
        @include text-semi-small(bold);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet-small) {
  .Greeting {
    height: 3rem;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .InfoTextSmall {
    white-space: wrap;
  }
}

@import '../../../../../assets/scss/globals';

$sidebar-action-height: calc(2 * #{$global-padding-large});

.Filter {
  .filterLegend {
    @include text-small(bold);
    color: $color-nightrider;
    margin-bottom: $global-margin-extra-small;

    &:not(:first-child) {
      margin-top: $global-margin-extra-large;

      @media screen and (max-width: $breakpoint-tablet) {
        margin-top: $global-margin-large;
      }
    }

    > [class*='bx--form-item'][class*='bx--checkbox-wrapper'] {
      margin-top: $global-margin-extra-small;
    }
  }

  [class*='bx--multi-select'] {
    max-width: 20rem;
  }

  .filterItem {
    margin-top: $global-margin-large;
  }

  .filterCheckboxGroup {
    display: flex;
    margin-bottom: $global-margin-medium;
    flex-wrap: wrap;

    &:last-child {
      padding-bottom: $sidebar-action-height;
    }
  }

  .checkboxLabel {
    &[class*='bx--fieldset'] {
      flex-basis: 50%;
      margin-bottom: $global-margin-medium;}

    [class*='bx--checkbox-label-text'] {
      max-width: 17ch;
    }
  }

  .noMargin {
    margin-top: 0;
    margin-bottom: 0;
  }

  .availableByFilter {
    margin-bottom: $global-margin-medium;
  }

  .objectFilterGroup {
    > div + div {
      margin-top: $global-margin-medium;
    }
  }

  .datePicker {
    display: flex;

    &Container {
      position: relative;

      &Right {
        > div {
          left: -10rem !important;
        }
      }
    }

    input {
      max-width: 8rem;
      margin-bottom: $global-margin-small;

      &:first-child {
        margin-right: $global-margin-large;
      }
    }
  }

  .TagsSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 3.25rem;

    .MiscTags:not(legend) {
      grid-column: span 2;
      max-height: 16rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      grid-column-gap: 2rem;

      > legend {
        width: 100%;
      }

      > * {
        flex-grow: 0;
      }
    }
  }

  .FilterSaveBar {
    > div {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;

      > [class*='global-content-wrapper'] {
        justify-content: center;
      }
    }

    .SaveButton {
      min-width: 100%;
      justify-content: center;

      &[class*='bx--btn']:not(.bx--btn--hasIcon):not(.bx--btn--icon-only):not(.bx--btn--sm) {
        padding-right: $global-padding-medium;
        padding-left: $global-padding-medium;
      }

      svg {
        fill: $color-white;
        margin-left: $global-margin-extra-small;
      }
    }
  }
}

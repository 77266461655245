@import 'src/assets/scss/globals';

.bx--modal-container {
  background-color: $color-white;

  .bx--modal-header {
    margin-bottom: 3px;

    .bx--modal-header__label {
      @include text-semi-large(bold);
      color: $color-nightrider;
    }

    .bx--modal-close {
      background-color: $color-white;
      color: $color-charcoal;
      overflow: unset;
      top: 1px;

      &:hover {
        background-color: $color-gainsboro;
        color: $color-nightrider;
      }

      &:focus {
        border: 2px solid $color-royal-blue;
      }
    }
  }

  .bx--modal-content {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    border-top: 1px solid $color-dark-grey;
    padding-top: 0.125rem;
    overflow-y: auto;

    .bx--modal-custom-content {
      padding: 1rem 1rem 2rem;

      h3 {
        @include text-small(bold);
        color: $color-nightrider;
        margin-bottom: $global-margin-medium;
      }

      p:not(.bx--inline-notification__title) {
        @include text-small();
        color: $color-nightrider;
        margin-bottom: $global-margin-medium;
      }
    }

    .bx--modal-custom-footer {
      background-color: $color-solitude-light;
      padding: 0.75rem;
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      > * {
        margin-left: $global-margin-medium;

        &.hasIcon {
          padding-right: 3rem !important;
        }

        &:only-child {
          margin-left: 0;
        }
      }
    }
  }
}

.bx--modal--with-tabs {
  .bx--modal-header {
    h2 {
      padding-left: $global-padding-small;
    }
  }

  .bx--modal-container .bx--modal-content {
    border: 0;

    .bx--modal-custom-content {
      padding: $global-padding-large;
      min-height: 15rem;

      .bx--form-item {
        margin-bottom: $global-margin-medium;
      }
    }
  }
}

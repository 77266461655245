$bx-search-focus-box-shadow: inset -2px 2px 0 0 $color-royal-blue, inset -2px -2px 0 0 $color-royal-blue;

.bx--search {
  .bx--search-input {
    @include input-field;

    &:focus ~ .bx--search-close {
      &:hover,
      &:active {
        box-shadow: $bx-search-focus-box-shadow;
        outline: 0;
        border: 0;
      }

      .bx--search-close:hover {
        box-shadow: $bx-search-focus-box-shadow;
      }
    }
  }

  &:focus {
    outline: 2px solid $color-royal-blue;
  }

  .bx--search-magnifier {
    fill: $color-nightrider;
  }

  .bx--search-close {
    fill: $color-nightrider;
    color: transparent;

    &::before {
      display: none;
    }

    &:hover,
    &:active {
      background-color: $color-gainsboro;
      border: 2px solid $color-gainsboro;

      &::before {
        display: none;
      }
    }

    &:focus,
    &:active,
    &:active:hover {
      outline: 2px solid $color-royal-blue;
    }
  }
}

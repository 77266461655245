@import '../../../assets/scss/globals';

.Mail {
  padding: $global-padding-medium $global-padding-extra-large;
  border: 1px solid $color-dark-grey;
  border-radius: 0 8px 8px;
  margin-right: $global-margin-large;
  margin-bottom: $global-margin-medium;
  width: 100%;

  &.close {
    .mailContent {
      max-height: 4rem;
    }

    .subject {
      max-height: 1.5rem;
      overflow: hidden;
    }
  }

  &.unseen {
    border-color: $color-lochmara;

    .sender {
      position: relative;

      &::before {
        @include text-medium();
        content: '●';
        position: absolute;
        top: -0.25rem;
        left: -1rem;
        color: $color-lochmara;
      }
    }
  }

  &.reply {
    border-radius: 8px 0 8px 8px;
    background-color: $color-white-smoke;
    margin-right: 0;
    margin-left: $global-margin-large;
  }

  .mailHeader {
    display: flex;
    flex-flow: column;

    .sender {
      @include text-extra-small();
      color: $color-nightrider;
      margin-bottom: $global-margin-small;
      position: relative;

      [class*='bx--btn--ghost'] {
        position: absolute;
        top: -0.75rem;
        right: -1.75rem;
        padding: 0.75rem;

        [class*='bx--btn__icon'] path {
          fill: $color-nightrider;
        }

        &:hover {
          background-color: $color-gainsboro;
        }
      }
    }

    .subject {
      @include text-medium();
      color: $color-nightrider;
      margin-bottom: $global-margin-small;

      svg {
        width: 1.25rem;
        height: 1.25rem;
        transform: translateY(0.125rem);
      }
    }

    .receiver {
      @include text-extra-small();
      color: $color-nightrider;
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 1.25rem;
      }

      address {
        display: inline;
      }
    }
  }

  .mailContent {
    @include text-small();
    color: $color-nightrider;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 0.5rem;
    line-height: normal;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $color-nightrider;
      margin: 0;
      padding: 0;
      border: 0;
    }

    h1 {
      @include text-extra-large();
    }

    h2 {
      @include text-semi-extra-large();
    }

    h3 {
      @include text-large();
    }

    h4 {
      @include text-semi-large();
    }

    p {
      @include text-small();
      color: $color-nightrider;
    }

    b,
    strong {
      @include text-small(bold);
    }

    i,
    em {
      font-style: italic;
    }

    ul {
      list-style: disc;
      margin-left: 1.5rem;
    }

    ol {
      list-style: decimal;
      margin-left: 1.5rem;
    }

    a {
      color: $color-lochmara;
      line-height: 1.3rem;
      text-decoration: underline;

      &:hover {
        color: $color-dark-blue;
        text-decoration: none;
      }
    }
  }

  .mailAttachment {
    margin-top: $global-margin-medium;

    [class*='bx--btn--secondary'] {
      @include white-tag();
      border-radius: 0.9375rem;
      padding: 0.25rem 1.75rem 0.25rem 0.5rem;
      min-height: 1.5rem;

      &:focus:not(:disabled) {
        outline: 0;
      }

      [class*='bx--btn__icon'] {
        right: 0.25rem;
        width: 1.25rem;
        height: 1.25rem;

        path {
          fill: $color-lochmara;
        }
      }
    }
  }
}

@import '../../../assets/scss/globals';

$listing-phone-breakpoint: 500px;
$listing-tablet-breakpoint: 900px;

.Listing {
  display: flex;
  padding: 0 !important;
  transition: box-shadow 0.2s ease-out;
  position: relative;

  .skeleton {
    @include skeleton();
  }

  &:hover {
    @media screen and (min-width: $listing-tablet-breakpoint) {
      cursor: pointer;
      box-shadow: 0 3px 6px rgba($color-black, 0.32);
    }
  }

  @media screen and (max-width: $listing-tablet-breakpoint) {
    flex-wrap: wrap;
  }

  .previewImage {
    width: 21.5rem;
    height: auto;
    max-height: 17rem;
    object-fit: cover;
    flex: 0 0 auto;

    @media screen and (max-width: $breakpoint-phone) {
      width: 18rem;
    }

    @media screen and (max-width: $listing-tablet-breakpoint) {
      height: 16.5rem;
      width: 100%;
    }

    &.placeholderImage {
      @media screen and (max-width: $listing-tablet-breakpoint) {
        height: 9rem;
      }

      @media screen and (max-width: $listing-phone-breakpoint) {
        height: 5rem;
      }
    }
  }

  .editableContent {
    position: absolute;
    right: 0;
    top: 0;
    width: 10rem;
    height: 3rem;
    cursor: default;
  }

  .listingWrapper {
    position: relative;
    flex: 1 1 auto;
    max-width: 100%;

    [class*='bx--form-item'] {
      position: absolute;
      top: 0;
      right: 6rem;

      [class*='bx--toggle__text--on'],
      [class*='bx--toggle__text--off'] {
        @include text-extra-small();
      }

      @media screen and (max-width: $listing-phone-breakpoint) {
        display: none;
      }
    }

    .link {
      position: absolute;
      right: 1rem;
      bottom: 1rem;

      @media screen and (max-width: $listing-phone-breakpoint) {
        display: none;
      }
    }

    [class*='bx--overflow-menu'] {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }

  .listingContent {
    padding: $global-padding-large $global-padding-extra-large $global-padding-extra-large;

    @media screen and (max-width: $listing-phone-breakpoint) {
      padding: $global-padding-medium;

      .infoIcon:nth-last-child(2) {
        display: none;
      }
    }

    h3,
    h2 {
      @include text-medium(bold);
      color: $color-nightrider;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tagContainer {
    max-width: 100%;

    > :not(:first-child):not(.tagCounter):not(:nth-child(2)):not(:nth-child(3)) {
      display: none;
    }

    @media screen and (max-width: $listing-phone-breakpoint) {
      > :not(:first-child):not(.tagCounter) {
        display: none;
      }
    }
  }

  .cardInfoPanel {
    margin-top: $global-margin-extra-large;

    @media screen and (max-width: $listing-phone-breakpoint) {
      > :nth-last-child(2) {
        display: none;
      }

      > div {
        max-height: 2rem;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-right: 0.75rem;

        > span:nth-last-child(2) {
          @include text-extra-small(bold);
          white-space: nowrap;
        }

        svg {
          margin-top: $global-margin-extra-small;
          margin-bottom: $global-margin-small;
          margin-right: $global-margin-extra-small;
        }
      }
    }
  }

  .address {
    @include text-small();
    line-height: normal;
    color: $color-nightrider;
    display: flex;
    margin-top: $global-margin-medium;
    margin-bottom: $global-margin-extra-small;

    svg {
      fill: $color-nightrider;
      width: 1rem;
      height: 1rem;
    }
  }

  [class*='bx--tag'] {
    @include text-small();
    background-color: $color-gainsboro;
    color: $color-nightrider;
  }
}

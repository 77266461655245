/*
 * This file has been created by Ergosign GmbH - All rights reserved - http://www.ergosign.de
 * DO NOT ALTER OR REMOVE THIS COPYRIGHT NOTICE OR THIS FILE HEADER.
 *
 * This file and the code contained in it are subject to the agreed contractual terms and conditions,
 * in particular with regard to resale and publication.
 */

@mixin input-label {
  @include text-extra-small;
  color: $color-charcoal;
  text-align: left;

  &.bx--label--disabled {
    color: $color-suva-grey;
  }

  &.bx--skeleton {
    background-color: $color-gainsboro;
  }
}

@mixin input-field {
  @include placeholder;
  @include text-small;
  border: 1px solid $color-dark-grey;
  border-radius: 2px;
  background-color: $color-white;
  color: $color-nightrider;

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    outline: 2px solid $color-royal-blue;
  }

  &:disabled {
    border: 1px solid $color-gainsboro;
    background-color: $color-white;
    color: $color-suva-grey;
  }

  &.bx--skeleton {
    background-color: $color-gainsboro;
    border: 0;
  }

  &[data-invalid='true']:not(:focus),
  &[aria-invalid='true']:not(:focus) {
    outline-color: $color-wildberry !important;

    + svg {
      fill: $color-wildberry;
    }

    .bx--number__invalid {
      fill: $color-wildberry;
    }
  }
}

%button-ghost {
  background-color: transparent;
  color: $color-lochmara;

  &.bx--btn--icon-only .bx--btn__icon,
  &.bx--btn--icon-only .bx--btn__icon path {
    fill: $color-lochmara;
  }

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background-color: $color-solitude-light;
    color: $color-dark-blue;
    border-color: $color-solitude-light;
  }

  &:focus:not(:disabled) {
    border-color: $color-royal-blue;
    box-shadow: inset 0 0 0 1px $color-royal-blue, inset 0 0 0 2px $color-white;
  }

  &:disabled {
    background-color: transparent;
    border-color: transparent;
    color: $color-suva-grey;
  }
}

%focus-outline {
  outline: 3px solid $color-royal-blue;
}

@mixin dropdown {
  @include text-small;
  border: 1px solid $color-dark-grey;
  border-radius: 2px;
  background-color: $color-white;
  color: $color-nightrider;

  &--disabled {
    border: 1px solid $color-white-smoke-darker;
    background-color: $color-white;
    color: $color-suva-grey;
  }

  &[data-invalid='true']:not(:focus) {
    outline-color: $color-wildberry;

    .bx--list-box__invalid-icon {
      fill: $color-wildberry;
    }

    + .bx--form-requirement {
      color: $color-wildberry;
    }
  }
}

%global-content-padding {
  padding: $global-padding-medium var(--global-content-horizontal-spacing) $global-padding-extra-extra-large
    var(--global-content-horizontal-spacing);

  @media screen and (max-width: $breakpoint-desktop) {
    padding: $global-padding-medium var(--global-content-horizontal-spacing) $global-padding-extra-large
      var(--global-content-horizontal-spacing);
  }

  @media screen and (max-width: $breakpoint-tablet) {
    padding: $global-padding-medium var(--global-content-horizontal-spacing) $global-padding-large
      var(--global-content-horizontal-spacing);
  }

  @media screen and (max-width: $breakpoint-phone) {
    padding: $global-padding-small var(--global-content-horizontal-spacing) $global-padding-medium
      var(--global-content-horizontal-spacing);
  }
}

@mixin card-drop-shadow {
  box-shadow: 0 3px 6px rgba($color-black, 0.16);
}

@mixin white-tag {
  @include text-extra-small();
  color: $color-nightrider;
  background-color: $color-white;
  border: 1px solid $color-dark-grey;
  margin-right: $global-margin-small;
}

@mixin required-form-item-label {
  &:not([class*='bx--radio-button__label']):not([class*='bx--checkbox-label']) {
    &::after {
      @include text-small;
      content: '*';
      color: $color-lochmara;
      line-height: $font-size-smaller;
    }
  }
}

@mixin required-tooltip {
  @include text-extra-extra-extra-small();
  position: absolute;
  right: 1.25rem;
  top: 0;
  color: $color-nightrider;
  margin-top: $global-margin-extra-small;

  &::before {
    content: '*';
    margin-right: $global-margin-extra-small;
    color: $color-lochmara;
  }
}

@mixin tag($inactive: null) {
  &[class*='bx--tag'] {
    @include text-extra-small;
    background-color: $color-citrus;
    color: $color-black;
    @if $inactive == 'inactive' {
      background-color: $color-suva-grey;
    }
  }
}

@mixin content-max-width-wrapper($block: null) {
  max-width: $global-content-max-width;
  margin: auto;
  display: flex;

  @if $block == 'block' {
    display: block;
  }
}

@mixin info-text() {
  @include text-small();
  color: $color-charcoal;
}

@mixin icon-button() {
  padding-left: $global-padding-medium !important;
  padding-right: 2.5rem !important;
}

@mixin plain-html-style {
  p {
    @include text-small;
    color: $color-nightrider;
    margin-bottom: 1rem;
    line-height: 1.6;
  }

  ul {
    list-style-type: square;
    margin-bottom: $global-margin-medium;

    li {
      @include text-small;
      color: $color-nightrider;
      line-height: 1.6;
      margin-left: $global-margin-medium;
    }
  }

  a {
    @include text-small;
    color: $color-lochmara;
    box-sizing: border-box;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: $color-royal-blue;
    }

    &:focus {
      outline: 2px solid $color-royal-blue;
    }

    &:active {
      color: $color-dark-blue;
      border-bottom: 0;
      margin-bottom: 0;
    }

    &[target='_blank']::after {
      background: url('../../assets/svg/extlink.svg') center no-repeat;
      padding-right: 10px;
      content: '';
      margin-left: 5px;
      margin-right: 3px;
      display: inline-block;
      height: 10px;
      width: 0;
    }
  }
}

@import '../../../assets/scss/globals';

.BackNavigation {
  background-color: $color-solitude-light;
  margin: 0;

  [class*='global-content-wrapper'] {
    padding: 1.25rem var(--global-content-horizontal-spacing);
    text-align: left;
  }

  .BackNavigationLink {
    display: flex;
    align-items: center;
    width: fit-content;

    svg {
      height: 2rem;
      min-width: 2rem;
      fill: $color-lochmara;
    }

    .TitleContainer {
      display: flex;
      flex-wrap: wrap;
      margin-left: $global-margin-medium;
      align-items: center;
    }

    h1 {
      @include text-semi-large(bold);
      color: $color-nightrider;
      margin-right: $global-margin-small;
    }

    .SubTitle {
      @include text-semi-large;
      color: $color-charcoal;
      margin-right: $global-margin-medium;
    }

    &:hover,
    &:focus {
      svg {
        fill: $color-dark-blue;
      }
    }

    &:focus {
      outline: 2px solid $color-royal-blue;
    }
  }

  @media screen and (max-width: $breakpoint-tablet) {
    .BackNavigationLink {
      h1 {
        @include text-medium(bold);
      }
    }

    [class*='global-content-wrapper'] {
      padding: 1rem var(--global-content-horizontal-spacing);
    }
  }

  @media screen and (max-width: $breakpoint-tablet) {
    .BackNavigationLink {
      h1 {
        @include text-semi-small(bold);
      }
    }

    [class*='global-content-wrapper'] {
      padding: 0.75rem var(--global-content-horizontal-spacing);
    }
  }
}

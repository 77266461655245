@import 'src/assets/scss/globals';

.bx--radio-button {
  + .bx--radio-button__label {
    color: $color-nightrider;
    padding-right: 1rem;

    .bx--radio-button__appearance {
      border-color: $color-lochmara;
      border-width: 2px;
      margin-left: 0;
    }
  }

  &:checked {
    + .bx--radio-button__label .bx--radio-button__appearance {
      border-color: $color-lochmara;

      &::before {
        background-color: $color-lochmara;
      }
    }
  }

  &:focus {
    + .bx--radio-button__label .bx--radio-button__appearance {
      box-shadow: 0 0 0 2px $color-royal-blue;
    }
  }
}

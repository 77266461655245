@import '../../../../assets/scss/globals';

.SearchRequestsListFilterContainer {
  margin-bottom: 0;

  [class*='global-content-wrapper'] {
    > div {
      > *:not(button):not(:first-child) {
        flex: 1 1 12rem;
      }

      > *:first-child {
        flex: 1 0 20rem;
      }

      .FilterPlaceholder {
        height: 4.125rem;
      }
    }
  }
}

.Content {
  @extend %global-content-padding;

  tbody tr {
    cursor: pointer;

    &:hover {
      background-color: $color-solitude-light;
    }
  }

  .Pagination {
    display: flex;
    justify-content: center;
    margin-top: $global-margin-extra-large;
  }
}

.bullet {
  color: $color-lochmara;
}

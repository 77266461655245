.bx--label {
  @include input-label;
  color: $color-charcoal;
}

.bx--form-item {
  .bx--label {
    @include input-label;
  }
}

.bx--form__helper-text {
  @include input-label;
  @include text-extra-extra-extra-small();
  text-align: left;
}

.bx--text-input {
  @include input-field;
  min-width: 5rem;

  &[readonly] {
    border: 0;
    padding: 0;
    height: 2rem;

    &:focus:not(:disabled) {
      outline: none;
    }
  }

  &__field-wrapper[data-invalid='true'] {
    .bx--text-input__invalid-icon {
      fill: $color-wildberry;
    }
  }
}

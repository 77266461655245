@import '../../../assets/scss/globals';

.EditContactDetailsDialog {
  .SaveBar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .EditContactDetailsContent {
    .EditContactDetailsForm {
      margin-top: $global-margin-medium;
      margin-bottom: 0;

      > * {
        // extra space for save bar
        &:last-child {
          margin-bottom: 5rem;
        }
      }
    }
  }

  .ContactDataTable {
    max-width: 30rem;

    .ContactDataTableHeader {
      display: none;
    }

    tr {
      @include text-small;
      color: $color-nightrider;
      border-bottom: 0 !important;
      height: 2rem;

      &.ContactDataRowChecked {
        td {
          &:last-child {
            @include text-small('bold');
          }
        }
      }

      td {
        height: 2rem !important;

        &:last-child {
          justify-content: flex-start !important;
        }

        &:first-child {
          padding-left: 0;
          align-items: center;
        }

        svg {
          height: 1.25rem;
          margin-top: 0.25rem;
        }
      }
    }
  }
}

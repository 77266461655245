@import '../../../assets/scss/globals';

.ProfileDialog {
  div[class*='bx--modal-custom-content'] {
    position: relative;

    > .SectionHeading {
      @include text-semi-small;

      &WithSubtitle {
        margin-bottom: 0;
      }
    }
  }

  .ContactDataOverview {
    color: $color-nightrider;
    display: grid;
    grid-template-columns: minmax(12rem, max-content) 1fr;
    gap: $global-margin-medium;
  }

  .ContactDataType {
    @include text-small;
    color: $color-nightrider;
    display: flex;
    align-items: center;

    svg {
      height: 1.5rem;
      fill: $color-nightrider;
      margin-right: $global-margin-small;
    }
  }

  .Divider {
    border: 1px solid $color-gainsboro;
    margin: $global-margin-medium 0;
  }

  .Icon {
    height: 1.5rem;
    width: 1.5rem;
    fill: $color-lochmara;
    margin-right: $global-margin-small;
  }

  .ContactDataRowWithIcon {
    display: flex;
    align-items: center;

    &:first-child {
      margin-top: $global-margin-medium;
    }
  }

  .EditContactDataButton {
    position: absolute;
    top: $global-margin-small;
    right: $global-margin-medium;
  }

  .CredentialsRow {
    display: flex;
  }

  .CredentialsRow,
  .ReadOnlyInputWrapper {
    label {
      margin-bottom: 0;
    }

    .CredentialsInputReadOnly {
      @include text-small(bold);
      height: 1.5rem;
      text-overflow: ellipsis;
      margin-right: $global-margin-medium;
    }
  }

  label[class*='bx--toggle-input__label'] {
    @include text-small;
    color: $color-nightrider;

    [class*='bx--toggle__switch'] {
      margin-top: $global-margin-extra-small;
    }
  }

  .NotificationsRow {
    display: flex;

    > * + * {
      margin-left: $global-margin-medium;
    }
  }

  .ChangeCredentials,
  .ChangeProfileData {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: auto;
    max-width: 55%;

    > * {
      min-width: 22rem;

      &:first-child {
        margin-bottom: $global-margin-small;
        margin-top: $global-margin-medium;
      }

      // extra space for save bar
      &:last-child {
        margin-bottom: calc(5rem + #{$global-margin-medium});
      }
    }
  }

  .ChangeProfileData {
    max-width: 70%;

    label {
      @include required-form-item-label;
    }

    .FormRow {
      display: flex;
      margin-top: $global-margin-small;
      gap: $global-margin-small;

      .Salutation {
        min-width: 7rem;
      }
    }

    .street {
      > :last-child {
        flex: 0 1 20%;
      }
    }

    .city {
      > :first-child {
        flex: 0 1 30%;
      }
    }

    .MailInfo {
      display: flex;
      margin-top: $global-margin-large;

      > .Icon {
        flex: 0 0 auto;
      }

      span {
        display: inline-block;
      }

      .Mail {
        @include text-small(bold);
        color: $color-nightrider;
        line-height: 1.5rem;
      }

      .Info {
        @include text-extra-small;
        color: $color-charcoal;
        margin-top: $global-margin-extra-small;
      }
    }
  }

  .SaveBar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    .ChangeProfileData {
      max-width: none;

      > * {
        min-width: 0;
      }
    }

    .ChangeCredentials {
      max-width: 75%;

      > * {
        min-width: 0;
      }
    }
  }

  @media screen and (max-width: $breakpoint-tablet-small) {
    .ContactDataOverview {
      color: $color-nightrider;
      display: grid;
      grid-template-columns: 1fr;
      gap: $global-margin-medium;
    }

    .ContactDataRowWithIcon {
      &:first-child {
        margin-top: 0;
      }
    }

    .NotificationsRow,
    .CredentialsRow {
      flex-direction: column;
      align-items: flex-start;

      > *:not(button) {
        margin-bottom: $global-margin-medium;
        width: 100%;
      }

      > * + * {
        margin-left: 0;
      }
    }

    div[class*='bx--modal-custom-content'] {
      .SectionHeading {
        margin-bottom: $global-margin-small;
      }
    }

    .ChangeProfileData {
      max-width: none;

      > *:first-child {
        margin-top: $global-margin-small;
        margin-bottom: $global-margin-small;
      }

      .FormRowWrap {
        flex-wrap: wrap;
      }
    }

    .ChangeCredentials {
      max-width: none;
    }
  }
}

@import '../../../assets/scss/globals';

// We use partially px here instead of rem because the map context is in px
.ListingPopup {
  visibility: visible;
  background-color: $color-white;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  margin: 1px;
  height: 264px;
  width: 232px;
  position: absolute;
  display: grid;

  .favoriteButtonContainer {
    position: relative;
  }

  .previewImage {
    height: 144px;
    width: 232px;
    object-fit: cover;
  }

  .listingContent {
    margin-top: 4px;
    padding: $global-padding-small;
    display: block;
    height: 120px;

    h2 {
      @include text-small(bold);
      color: $color-nightrider;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 216px;
    }

    .address {
      @include text-extra-small();
      line-height: normal;
      color: $color-nightrider;
      display: flex;
      margin-top: 14px;
      margin-bottom: 12px;

      svg {
        fill: $color-nightrider;
        width: 1rem;
        height: 1rem;
      }
    }

    .listingInfo {
      position: relative;
      display: flex;
      margin-top: 10px;

      span {
        @include text-small(bold);
        color: $color-nightrider;
        flex: 1 1 100%;
        border-right: 2px solid $color-dark-grey;
        margin-right: $global-margin-small;

        &:last-child {
          border: 0;
        }
      }
    }

    .link {
      width: 1.75rem;
      height: 1.75rem;
      position: absolute;
      right: $global-margin-small;
      bottom: 10px;
      fill: $color-lochmara;
    }
  }
}

.listingError {
  p {
    @include text-extra-small();
    color: $color-nightrider;
    padding: 0 $global-padding-medium;
  }
}

@import 'src/assets/scss/globals';

.CommentBox {
  border: 1px solid $color-dark-grey;
  border-radius: 8px;
  background: $color-white-smoke;
  padding: $global-padding-medium;
  position: relative;

  .User {
    @include text-extra-small(bold);
    color: $color-nightrider;
    margin-right: $global-margin-extra-small;
  }

  .Date {
    @include text-extra-small;
    color: $color-empress;
  }

  .Comment {
    @include text-small;
    margin-top: $global-margin-small;
    color: $color-nightrider;
  }

  .Menu {
    margin: $global-margin-small;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__EditMode {
    border: 0;
    background: $color-white;

    .EditComment {
      margin-top: $global-margin-small;
      margin-left: -$global-margin-medium;
      margin-right: -$global-margin-medium;

      > * + * {
        margin-top: $global-margin-small;

        > * + * {
          margin-left: $global-margin-medium;
        }
      }
    }
  }
}

@import '../../../assets/scss/globals';

$info-panel-icon-size-normal: 1.5rem;
$info-panel-icon-size-large: 2rem;

.InfoIconContainer {
  display: flex;

  > * + * {
    margin-left: $global-margin-extra-large;
  }

  .infoIcon {
    display: flex;
    flex-flow: column;
    align-items: center;

    .title {
      @include text-small(bold);
      color: $color-nightrider;
      margin-top: $global-margin-extra-small;
      text-align: center;
    }

    .subtitle {
      @include text-extra-extra-extra-small();
      color: $color-nightrider;
      margin-top: $global-margin-extra-small;
    }

    svg {
      fill: $color-suva-grey;
      width: $info-panel-icon-size-normal;
      height: $info-panel-icon-size-normal;
    }
  }

  &Large {
    > * + * {
      margin-left: 4rem;
    }

    .infoIcon {
      .title {
        @include text-medium(bold);
        margin-top: $global-margin-small;
      }

      .subtitle {
        @include text-extra-small();
        color: $color-charcoal;
      }

      svg {
        width: $info-panel-icon-size-large;
        height: $info-panel-icon-size-large;
      }
    }

    &.InfoIconContainerSkeleton {
      .infoIcon {
        .title {
          [class*='bx--skeleton__text'] {
            height: $font-size-medium;
          }
        }
      }
    }
  }

  &NoIcons {
    svg {
      display: none;
    }
  }

  @media screen and (max-width: $breakpoint-tablet) {
    > * + * {
      margin-left: $global-margin-large;
    }

    .infoIcon {
      .title {
        @include text-small(bold);
      }

      .subtitle {
        @include text-extra-extra-extra-small();
      }

      svg {
        width: $info-panel-icon-size-normal;
        height: $info-panel-icon-size-normal;
      }
    }
  }
}

.InfoIconContainerSkeleton {
  .infoIcon .title {
    [class*='bx--skeleton__text'] {
      margin-bottom: 0;
      height: $font-size-small;
      text-align: left;
    }
  }
}

@import '../../../assets/scss/globals';
$save-bar-height: 5rem;

.SaveBar {
  height: $save-bar-height;
  background-color: $color-solitude-light;
  margin-bottom: $global-margin-extra-small;
  transform: translateY(0%);
  padding-left: 48px; // Add space for privacy policy button

  &PlaceHolder {
    min-height: $save-bar-height;
    width: 100%;
  }

  &__fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: -$save-bar-height;
    filter: drop-shadow(0 0 4px $color-empress);
    transform: translateY(-100%);
    transition: transform 0.2s ease-in-out;
    z-index: $z-index-save-bar;
  }

  [class*='global-content-wrapper'] {
    justify-content: space-between;
    padding: $global-padding-medium var(--global-content-horizontal-spacing);
    align-items: center;
  }

  @media screen and (max-width: $breakpoint-desktop) {
    height: 4.5rem;
    align-items: center;

    &__fixed {
      margin-bottom: -4.5rem;
    }

    &PlaceHolder {
      min-height: 4.5rem;
    }
  }

  @media screen and (max-width: $breakpoint-phone) {
    height: 3.5rem;

    &__fixed {
      margin-bottom: -3.5rem;
    }

    [class*='global-content-wrapper'] {
      padding: $global-padding-small var(--global-content-horizontal-spacing);
    }

    &PlaceHolder {
      min-height: 3.5rem;
    }
  }
}

@import '../../../assets/scss/globals';

.SearchRequestCard {
  padding: 0;
  position: relative;

  .content {
    padding: $global-padding-medium;

    h3 {
      @include text-medium(bold);
      margin-bottom: 1.25rem;
    }

    .searchCriteria {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: $global-margin-medium;

      table {
        margin-right: 6rem;

        @media screen and (max-width: 400px) {
          margin-right: 0;
        }

        tr {
          > th {
            @include text-extra-small();
            color: $color-nightrider;
            text-align: left;
            width: 8rem;
          }

          > td {
            @include text-small();
            color: $color-nightrider;
            padding-bottom: $global-padding-medium;
          }
        }
      }
    }
  }

  aside.results {
    position: absolute;
    top: $global-padding-medium;
    right: $global-padding-large;

    h4 {
      @include text-medium();
      color: $color-lochmara;
    }

    .resultLink {
      @include text-extra-extra-extra-small();
      color: $color-nightrider;
      text-decoration: none;
      display: flex;
      align-items: center;
      padding-right: $global-padding-small;

      svg {
        height: 1.25rem;
        width: auto;
        fill: $color-lochmara;
      }
    }
  }

  div.results {
    @include text-semi-large(bold);
    display: flex;
    flex-flow: row;
    align-items: center;
    color: $color-lochmara;
    margin-top: 1.75rem;

    .resultLink {
      @include text-semi-small();
      color: $color-lochmara;
      display: flex;
      align-items: center;
      padding-right: $global-padding-extra-small;

    }

    svg {
      height: auto;
      width: 1rem;
      fill: $color-lochmara;
    }
  }

  .TagsList {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    max-height: 8rem;
    width: max-content;

    @media screen and (max-width: 1200px) {
      max-height: 10rem;
    }

    @media screen and (max-width: 820px) {
      max-height: 14rem;
    }

    @media screen and (max-width: 620px) {
      max-height: 20rem;
    }

    @media screen and (max-width: 400px) {
      max-height: unset;
    }

    .ListingTag {
      @include text-extra-extra-small();
      color: $color-nightrider;
      display: flex;
      align-items: center;
      margin-bottom: $global-margin-small;
      width: 12rem;

      svg {
        fill: $color-nightrider;
        margin-right: $global-margin-small;
      }
    }
  }

  .footer {
    height: 3rem;
    background-color: $color-solitude-light;
    width: 100%;
    padding-left: $global-padding-medium;
    padding-right: $global-padding-medium;
    display: flex;
  }
}

@import '../../../assets/scss/globals';

.RangeFilter {
  padding: $global-padding-medium;
  display: flex;
  align-items: flex-end;

  > * + * {
    margin-left: $global-margin-small;
  }

  > span {
    margin-bottom: $global-margin-small;
    line-height: $font-size-large;
    color: $color-empress;
  }

  [class*='bx--number__input-wrapper']::after {
    right: 2.25rem;
  }

  &__inline {
    padding: 0;
  }
}

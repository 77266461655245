@import '../../../assets/scss/globals';

.AccountConfirmationDialog {
  .alignLeft {
    justify-content: flex-start !important;
  }

  .content {
    padding: $global-padding-medium;
    position: relative;

    p {
      @include text-small();
      color: $color-nightrider;
      margin: 1rem 0 2.5rem;
    }
  }
}

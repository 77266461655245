@import '../../../assets/scss/globals';

.DialogBackNavigation {
  display: flex;
  align-items: center;
  margin-bottom: $global-margin-medium;

  [class*='bx--btn__icon'] {
    width: 1.5rem;
    height: 1.5rem;
    fill: $color-lochmara;
  }

  [class*='bx--btn--secondary'] {
    padding: 0 0.5rem;
    max-width: 2.5rem;
  }

  h5 {
    @include text-extra-small(bold);
    color: $color-nightrider;
  }

  p {
    @include text-extra-extra-extra-small();
    color: $color-charcoal;
  }
}

.DialogBackNavigationInfo {
  margin-left: $global-margin-medium;
}

@import '../../../assets/scss/globals';

$footer-font-color: $color-white;


.FooterLink {
  color: $footer-font-color;
  line-height: 1.3rem;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 2px solid $color-royal-blue;
  }

  &__isExternal {
    &::after {
      background: url('../../../assets/svg/extlink_white.svg') center no-repeat;
    }
  }
}

@import '../../../assets/scss/globals';

.Tips {
  @extend %global-content-padding;
  @include plain-html-style;
  position: relative;
  max-width: 49rem;

  ol {
    list-style-type: decimal;
    margin-bottom: $global-margin-medium;

    li {
      @include text-small;
      color: $color-nightrider;
      line-height: 1.6;
      margin-left: $global-margin-medium;
      margin-bottom: $global-margin-medium;
    }
  }

  ul li {
    margin-bottom: $global-margin-small;
  }

  blockquote {
    background-color: $color-white-smoke;
    padding: $global-padding-extra-small $global-padding-medium;
    margin-bottom: $global-margin-large;
  }

  h2 {
    @include text-semi-extra-large(bold);
    color: $color-charcoal;
    margin-bottom: 1.25rem;

    &:not(:first-child) {
      margin-top: 2.5rem;
    }
  }

  h3 {
    @include text-semi-large(bold);
    color: $color-charcoal;
    margin-top: 1.5rem;
    margin-bottom: 1.25rem;
  }

  h4 {
    @include text-semi-small(bold);
    color: $color-charcoal;
    border-bottom: 1px solid $color-dark-grey;
    padding-bottom: 3px;
    text-transform: uppercase;
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
  }
}

@import 'src/assets/scss/globals';

.bx--multi-select {
  text-align: left;
  border: 0;

  .bx--list-box__label {
    @include text-small;
    color: $color-nightrider;
  }

  .bx--tag {
    @include text-small;
    background-color: $color-lochmara;
    color: $color-white;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 2px $color-royal-blue;
    }

    .bx--tag__close-icon {
      width: 1.125rem;
      height: 1.125rem;
      margin-right: 0.125rem;
      margin-left: 0.25rem;

      &:hover {
        background-color: $color-solitude-light;

        svg {
          fill: $color-dark-blue;
        }
      }
    }
  }

  .bx--list-box__menu:focus {
    outline-offset: -1px;
  }

  .bx--list-box__field {
    @include dropdown;
  }

  .bx--list-box__menu-item__option {
    color: $color-nightrider;

    .bx--checkbox-wrapper {
      .bx--checkbox-label[data-contained-checkbox-state='true']::before {
        background-color: $color-lochmara;
        border: 1px solid $color-lochmara;
      }
    }
  }

  .bx--list-box__menu-icon {
    > svg {
      fill: $color-nightrider;
    }
  }

  &.bx--list-box--disabled {
    button {
      border-color: $color-gainsboro;
    }

    .bx--list-box__label {
      color: $color-suva-grey;
    }
  }
}

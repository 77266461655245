@import 'src/assets/scss/globals';

.bx--dropdown__wrapper {
  text-align: left;
  grid-template-rows: auto;

  .bx--dropdown {
    @include dropdown;

    &.SmallFlyout {
      // Variation with a small flyout
      .bx--list-box__menu {
        min-width: 6.5rem;
      }
    }
  }

  .bx--label {
    display: block;
  }

  .bx--list-box__field {
    padding: 0 2rem 0 1rem;
  }

  &.bx--dropdown__wrapper--inline {
    grid-gap: 0.5rem;

    .bx--dropdown {
      border: 0;
      background-color: transparent;
      color: $color-nightrider;
    }

    .bx--dropdown--open {
      background-color: $color-solitude-light;
    }

    .bx--label {
      @include text-smaller;
      color: $color-nightrider;
    }

    .bx--list-box__field {
      padding: 0 2.5rem 0 0.5rem;
      height: 2.5rem;
    }

    .bx--list-box__label {
      @include text-smaller;
      color: $color-lochmara;
    }

    .bx--list-box__menu {
      min-width: 14rem;
      left: auto;

      &-icon {
        > svg {
          fill: $color-nightrider;
        }
      }
    }

    .bx--list-box__menu-item__option {
      color: $color-nightrider;
    }

    .bx--list-box__menu-item--active {
      color: $color-nightrider;
      background-color: $color-white;
    }
  }
}

.bx--list-box__menu-icon {
  top: 0;
  height: 100%;
  width: auto;
}

.bx--dropdown-v2.bx--skeleton,
.bx--dropdown.bx--skeleton {
  background-color: $color-gainsboro;
}

.bx--list-box__field {
  height: 2.5rem;

  &:focus {
    outline: 2px solid $color-royal-blue;
  }
}

.bx--list-box--sm {
  .bx--list-box__field {
    height: 2rem;
  }
}

.bx--list-box__menu {
  @include text-small;
  color: $color-nightrider;
  background-color: $color-white;
  min-width: max-content;

  &:focus {
    outline: 1px solid $color-royal-blue;
  }

  .bx--list-box__menu-item--active {
    &.bx--list-box__menu-item--highlighted {
      background-color: $color-solitude-light;
    }
  }

  &-item {
    border-top: 1px solid $color-dark-grey;

    &__option {
      border: 0;

      &:focus {
        outline: 2px solid $color-royal-blue;
      }
    }

    &--active {
      background-color: $color-solitude-light;
    }

    &:hover {
      background-color: $color-solitude-light;
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .bx--dropdown__wrapper {
    text-align: left;

    .bx--list-box__field {
      padding: 0 1.5rem 0 1rem;
    }

    .bx--list-box__menu-icon {
      right: 0.5rem;
    }
  }
}

@import '../../../assets/scss/globals';

.ToTopButton {
  position: fixed;
  right: 0;
  bottom: 125px;
  visibility: hidden;
  transform: translate(100%, 0);
  opacity: 0;
  z-index: 200;
  transition: visibility 0.2s, opacity 0.2s, transform 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  border: 1px solid transparent;

  &__visible {
    visibility: visible;
    opacity: 1;
    transform: translate(0, 0);
  }

  &:focus-visible {
    border: 1px solid $color-royal-blue;
    box-shadow: inset 0 0 0 1px $color-royal-blue, inset 0 0 0 2px $color-white;
  }

  svg {
    display: block;
  }
}

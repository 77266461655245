@import '../../../assets/scss/globals';

.RentComposition {
  color: $color-nightrider;
  max-width: 21rem;
  height: min-content;

  &EditMode {
    background: $color-solitude-light;
    padding: $global-padding-extra-large $global-padding-large;

    .RentCompositionRow {
      span:first-child,
      li {
        color: $color-nightrider;
      }
    }
  }

  &Header {
    @include text-small(bold);
    margin-bottom: $global-margin-large;
    display: block;
  }

  &Row {
    @include text-small;
    margin-bottom: $global-margin-medium;
    display: flex;
    justify-content: space-between;

    span:first-child,
    li {
      color: $color-charcoal;
    }
  }

  &ResultRow {
    @include text-small(bold);
    padding-top: calc(#{$global-padding-small} + #{$global-padding-extra-small});
    border-top: 2px solid $color-nightrider;
    display: flex;
    justify-content: space-between;
  }

  .IncidentalCostsTypes {
    > * {
      margin-top: $global-margin-extra-small;

      &::before {
        content: '';
        margin-right: $global-margin-medium;
      }
    }

    &EditMode {
      > * {
        &::before {
          content: '\2013';
          margin-right: $global-margin-extra-small;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet-small) {
  .RentComposition {
    &Header {
      @include text-extra-small(bold);
      margin-bottom: $global-margin-medium;
    }

    &Row {
      @include text-extra-small;
      margin-bottom: $global-margin-small;
    }

    &ResultRow {
      @include text-extra-small(bold);
    }
  }
}

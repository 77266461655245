@import 'src/assets/scss/globals';

.bx--date-picker {
  input {
    @include input-field;

    + svg {
      display: none;
    }

    &:disabled:hover {
      border-bottom: 1px solid $color-gainsboro;
    }
  }

  &__calendar.open {
    border-radius: 0;
    background-color: $color-white;

    .flatpickr-month,
    .cur-year,
    .cur-month {
      color: $color-nightrider;
      margin-bottom: 0 !important;

      &:hover {
        background-color: $color-white;
      }
    }

    .cur-year {
      background-color: $color-white;

      &:focus {
        outline: 2px solid $color-royal-blue;
      }
    }

    .arrowUp,
    .arrowDown {
      &::after {
        border-bottom-color: $color-nightrider;
        border-top-color: $color-nightrider;
      }

      &:hover::after {
        border-bottom-color: $color-royal-blue;
        border-top-color: $color-royal-blue;
      }
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
      > svg {
        fill: $color-nightrider;
      }

      &:hover {
        background-color: $color-solitude-light;
      }
    }

    .flatpickr-innerContainer {
      span {
        color: $color-nightrider;

        &.flatpickr-day {
          &.selected {
            color: $color-white;
            background-color: $color-royal-blue;
            outline-color: $color-royal-blue;
          }

          &.today:not(.selected) {
            color: $color-royal-blue;

            &::after {
              background-color: $color-royal-blue;
            }
          }

          &.inRange {
            background-color: $color-solitude;
          }

          &:hover {
            color: $color-dark-blue;
            background-color: $color-solitude-light;
          }

          &:focus,
          &:active,
          &.endRange:hover,
          &.startRane:hover,
          &.selected:hover {
            outline-color: $color-royal-blue;
          }

          &.endRange,
          &.startRande,
          &.selected {
            &:focus:not(:hover) {
              outline-color: $color-white;
              outline-offset: -2px;
            }
          }

          &.flatpickr-disabled {
            color: $color-suva-grey;
          }
        }
      }
    }

    &:focus-within {
      outline: 2px solid $color-royal-blue;
    }
  }

  &-input__wrapper--invalid {
    + .bx--form-requirement {
      color: $color-wildberry;
    }
  }
}

@import '../../../../assets/scss/globals';

$activation-bar-height: 4rem;
$meta-data-field-width: 10rem;
$field-spacing: 0.875rem;
$line-height: 1rem;

.Content {
  @extend %global-content-padding;
  padding-bottom: $global-padding-medium;

  .AccountCard {
    display: grid;
    grid-template-columns: 0.8fr 1fr 1.2fr;
    gap: $global-margin-medium;

    label {
      margin-bottom: 0;
    }
  }

  .ApprovalState,
  .ReadonlyMetaData {
    display: flex;
    flex-direction: column;
    width: $meta-data-field-width;
    max-width: $meta-data-field-width;

    span {
      @include text-small;
      color: $color-nightrider;
      overflow: hidden;
      word-break: break-word;
    }

    &Label {
      @include text-extra-extra-small;
      font-size: $font-size-extra-extra-small !important;
      margin-bottom: $global-margin-small;
      color: $color-charcoal;
    }
  }

  .ApprovalState {
    span:not(:first-child) {
      @include text-small(bold);
    }
  }

  .ReadonlyMetaData {
    &__noSpace {
      margin-top: 0 !important;
    }

    span {
      line-height: 1rem;
    }
  }

  .MetaData {
    margin-right: 2.5rem;

    label {
      @include text-extra-extra-extra-small;
      margin-bottom: 0;
    }

    &Column {
      > :not(:first-child) {
        margin-top: $global-margin-medium;
      }
    }
  }

  .ProfileSection {
    display: grid;
    grid-template-columns: 1fr;

    &__withMetaData {
      grid-template-columns: max-content 1fr;
    }

    .ProfileData {
      > * {
        margin-bottom: $global-margin-medium;
      }

      .ContactTypeSelection {
        margin-bottom: $global-margin-medium;

        label {
          min-width: 8rem;
          display: flex;
          justify-content: flex-start;
        }
      }

      .SubGrid {
        display: grid;
        grid-template-columns: 1fr min-content 1fr;
        gap: $global-margin-medium;
        align-items: center;

        &SpanFull {
          grid-column: span 3;
          display: flex;

          > * {
            flex: 1 1 auto;
            margin-left: $global-margin-medium;

            &:first-child {
              margin-left: 0;
            }
          }

          &.street > :last-child {
            flex: 0 1 6rem;
          }

          &.city > :first-child {
            flex: 0 1 auto;
          }
        }
      }

      .salutation {
        flex: 0 1 6rem;
        min-width: 7rem;
      }
    }

    &__readonly {
      .ProfileData {
        label {
          margin-bottom: 0;
        }

        .SubGrid {
          display: grid;
          grid-template-columns: 1fr 2fr;

          &SpanFull {
            grid-column: span 2;
          }
        }

        [class*='bx--form-item'] {
          height: 2.5rem;
        }
      }
    }

    .FormRow {
      width: 100%;
      display: flex;
      justify-content: stretch;

      > [class*='bx--form-item'],
      > .RequiredFormItem {
        flex-grow: 1;
      }

      > :not(:first-child) {
        margin-left: $global-margin-medium;
      }
    }


    .ViewData {
      > :last-child {
        margin-top: $global-margin-medium;
      }

      .ContactData {
        display: flex;
        flex-flow: row;

        > * {
          margin-right: $global-margin-extra-extra-large;
        }

        h3 {
          @include input-label;
          margin: $field-spacing 0;
        }

        p {
          @include text-small();
          color: $color-nightrider;
        }
      }
    }

  }

  .ListingsSection {
    > p {
      @include text-small;
      color: $color-charcoal;
      margin-bottom: $global-margin-medium;
    }
  }
}

.ActivationBar {
  height: $activation-bar-height;
  background-color: $color-solitude-light;
  display: flex;
  padding: $global-padding-small $global-padding-medium;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: $global-padding-small;
  right: $global-padding-small;

  > :first-child {
    margin-right: $global-margin-small;
  }
}

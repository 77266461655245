@import 'src/assets/scss/globals';

@mixin ghost-button-styling {
  background-color: transparent;
  color: $color-lochmara;

  &.bx--btn--icon-only .bx--btn__icon,
  &.bx--btn--icon-only .bx--btn__icon path {
    fill: $color-lochmara;
  }

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background-color: $color-solitude-light;
    color: $color-dark-blue;
    border-color: $color-solitude-light;
  }

  &:focus:not(:disabled) {
    border-color: $color-royal-blue;
    box-shadow: inset 0 0 0 1px $color-royal-blue, inset 0 0 0 2px $color-white;
    background: $color-solitude-light;
    color: $color-dark-blue;
  }

  &:disabled {
    background-color: transparent;
    border-color: transparent;
    color: $color-suva-grey;

    &.bx--btn--icon-only .bx--btn__icon,
    &.bx--btn--icon-only .bx--btn__icon path {
      fill: $color-suva-grey;
    }
  }

  > svg {
    width: $font-size-large;
    height: $font-size-large;
  }

}

.bx--btn {
  @include text-small;

  &:not(.bx--btn--hasIcon):not(.bx--btn--icon-only):not(.bx--btn--sm) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media screen and (max-width: $breakpoint-phone) {
    &:not(.bx--btn--hasIcon):not(.bx--btn--icon-only):not(.bx--btn--sm) {
      padding-left: $global-padding-medium;
      padding-right: $global-padding-medium;
    }
  }

  &--small {
    height: 2rem;
    min-height: 2rem;
  }

  &--sm:not(.bx--btn--hasIcon) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &.bx--btn--primary {
    background-color: $color-lochmara;
    color: $color-white;

    &:hover:not(:disabled) {
      background-color: $color-dark-blue;
    }

    &:active:not(:disabled) {
      background-color: $color-lochmara;
    }

    &:focus:not(:disabled) {
      border-color: $color-royal-blue;
      box-shadow: inset 0 0 0 1px $color-royal-blue, inset 0 0 0 2px $color-white;
    }

    &:disabled {
      background-color: $color-gainsboro;
      border-color: $color-gainsboro;
      color: $color-suva-grey;
    }
  }

  &.bx--btn--secondary {
    background-color: transparent;
    border-color: $color-lochmara;
    color: $color-lochmara;

    &:hover:not(:disabled) {
      background-color: $color-solitude-light;
      color: $color-dark-blue;
      border-color: $color-dark-blue;
    }

    &:active:not(:disabled) {
      background-color: transparent;
      border-color: $color-lochmara;
      color: $color-lochmara;
    }

    &:focus:not(:disabled) {
      border-color: $color-royal-blue;
      outline: 1px solid $color-royal-blue;
      outline-offset: -4px;
      box-shadow: inset 0 0 0 1px $color-royal-blue, inset 0 0 0 2px $color-white;
    }

    &:disabled {
      background-color: transparent;
      border-color: $color-gainsboro;
      color: $color-suva-grey;
    }

    &.bx--btn--hasIcon {
      padding-right: 2.5rem;
    }
  }

  &.bx--btn--ghost {
    @include ghost-button-styling;

    &.bx--btn--sm.bx--btn--hasIcon {
      > svg {
        margin-left: $global-margin-extra-small;
        width: $font-size-semi-small;
        height: $font-size-semi-small;
      }

      &:not(:disabled) {
        color: $color-nightrider;

        > svg > path {
          fill: $color-lochmara;
        }

        &:hover,
        &:active {
          color: $color-dark-blue;

          > svg > path {
            fill: $color-dark-blue;
          }
        }
      }
    }
  }
}

.bx--btn-set .bx--btn.bx--btn--icon-only {
  width: auto;
}

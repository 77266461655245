@import './assets/scss/fonts';
@import './assets/scss/variables';
@import './node_modules/carbon-components/css/carbon-components.min';
@import './node_modules/react-toastify/dist/ReactToastify';
@import './node_modules/leaflet/dist/leaflet';
@import './assets/scss/overrides';
@import './node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import './node_modules/@glidejs/glide/src/assets/sass/glide.theme';

html {
  scroll-behavior: smooth;
}

body {
  @include text-base-family;
  font-size: 16px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.global-content-wrapper {
  @include content-max-width-wrapper;

  &-block {
    @include content-max-width-wrapper(block);
  }
}

.sr-only {
  // For informative text that should only be visible to screen readers
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

:root {
  @include global-vars();
}

@import '../../../assets/scss/globals';

$pagination-current-page-border-width: 0.25rem;

.Pagination {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .PaginationSettings {
    flex: 0 0 auto;
  }

  .PaginationStepper {
    flex: 0 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 3.5rem;

    Button {
      height: 100%;

      &:first-child {
        margin-right: $global-margin-extra-small;
      }

      &:last-child {
        margin-left: $global-margin-extra-small;
      }
    }

    .PaginationCurrentPage,
    .PaginationMaxPage {
      padding-top: $pagination-current-page-border-width;
      border-bottom: $pagination-current-page-border-width solid transparent;
      display: inline-block;
      min-width: 1rem;
      text-align: center;
    }

    .PaginationCurrentPage {
      border-color: $color-royal-blue;
    }
  }

  @media screen and (min-width: $breakpoint-desktop) {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-items: center;

    .PaginationSettings {
      margin-right: auto;
    }

    .PaginationStepper {
      grid-column-start: 2;
    }
  }
}

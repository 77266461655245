@import '../../../assets/scss/globals';

.AdminView {
  .AdminFilterContainer {
    [class*='global-content-wrapper-block'] {
      > div {
        align-items: flex-start;

        > *:not(button):not(:first-child) {
          flex: 0 1 275px;
        }

        > *:first-child {
          flex: 1 1 350px;
        }

        > button {
          margin-top: 1.5rem;
          padding: $global-padding-small $global-padding-medium;
        }
      }
    }
  }

  .content {
    padding: $global-padding-medium var(--global-content-horizontal-spacing) $global-padding-extra-extra-large
      var(--global-content-horizontal-spacing);
  }
}

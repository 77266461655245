@import '../../../assets/scss/globals';

$menu-border: 1px solid $color-gainsboro;
$menu-item-border-indictator-width: 3px;

.Menu {
  .FirstLevel {
    border-bottom: $menu-border;
  }

  ul {
    display: flex;
    flex-direction: column;
  }

  li {
    flex-wrap: wrap;
    display: flex;

    > a {
      flex: 1 1 auto;
    }

    > div {
      flex: 1 1 100%;
    }
  }

  li + li {
    border-top: $menu-border;
  }

  &Item {
    a {
      @include text-small;
      display: flex;
      color: $color-nightrider;
      text-decoration: none;
      height: 3.5rem;
      align-items: center;
      padding-left: calc(var(--global-content-horizontal-spacing) - #{$menu-item-border-indictator-width});
      border-left: $menu-item-border-indictator-width solid transparent;

      &:focus {
        outline: 2px solid $color-royal-blue;
        outline-offset: -2px;
      }

      &:active {
        outline: none;
      }

      &:hover {
        border-color: $color-dark-blue;
        color: $color-dark-blue;
      }
    }

    &__active {
      > a {
        @include text-small(bold);
        border-left: $menu-item-border-indictator-width solid $color-royal-blue;
      }
    }

    &__open {
      ul {
        border-top: $menu-border;
      }
    }

    &__subItem {
      margin-left: $global-margin-medium;
    }
  }

  .ExpansionButton {
    background: none;
    color: $color-suva-grey;
    border: 0;
    position: relative;

    &:active {
      border: 0;
    }

    &:focus:not(:disabled) {
      box-shadow: none;
      outline-offset: -2px;
      outline: 2px solid $color-royal-blue;
    }

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .DividerRight {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      border-right: 1px solid $color-suva-grey;
      right: 0;
      height: 50%;
    }
  }
}

@import '../../../assets/scss/globals';

.ListingContainer {
  > * {
    &:not(:first-child) {
      margin-top: $global-margin-large;
    }
  }
}

.NoListingText {
  @include text-small;
  color: $color-charcoal;

  &Small {
    @include text-smaller;
  }
}

.ListingCardSkeleton {
  @include skeleton;
  height: 14rem;
}

@import '../../../../../assets/scss/globals';

.bullet {
  color: $color-citrus;
  margin-right: $global-margin-extra-small;
}

.bulletInactive {
  color: $color-suva-grey;
}

/*
 * This file has been created by Ergosign GmbH - All rights reserved - http://www.ergosign.de
 * DO NOT ALTER OR REMOVE THIS COPYRIGHT NOTICE OR THIS FILE HEADER.
 *
 * This file and the code contained in it are subject to the agreed contractual terms and conditions,
 * in particular with regard to resale and publication.
 */

// Shades of grey
$color-white: #fff;
$color-white-smoke: #f7f7f7;
$color-white-smoke-darker: #f4f4f4;
$color-gainsboro: #e6e6e6;
$color-dark-grey: #b3b3b3;
$color-suva-grey: #8a8a8a;
$color-empress: #737373;
$color-charcoal: #4c4c4c;
$color-nightrider: #333;
$color-black: #000;

// Colorful colors
$color-solitude-light: #f3f7f8;
$color-solitude: #dbe2e9;
$color-royal-blue: #4889ce;
$color-lochmara: #3070b3;
$color-dark-blue: #0e3c6d;
$color-extra-dark-blue: #072140;
$color-citrus: #8c9800;
$color-tahitigold: #e37222;
$color-wildberry: #d30049;

@mixin global-vars {
  --global-content-horizontal-spacing: calc(#{$global-padding-extra-large});
  --global-default-vertical-spacing: calc(#{$global-padding-large});

  @media screen and (max-width: $breakpoint-desktop) {
    --global-content-horizontal-spacing: calc(#{$global-padding-large});
    --global-default-vertical-spacing: calc(#{$global-padding-medium});
  }

  @media screen and (max-width: $breakpoint-tablet) {
    --global-content-horizontal-spacing: calc(#{$global-padding-medium});
  }
}

@import '../../../assets/scss/globals';

.DisabledIcon {
  path {
    fill: $color-suva-grey;
  }
}

.InfoText {
  @include text-small;
  color: $color-charcoal;
  max-width: 39rem;
  white-space: pre;

  &Small {
    @include text-smaller;
  }
}

.ActiveTag {
  @include tag;

  &[class*='bx--tag'] {
    margin-left: $global-margin-small;
  }
}

.InactiveTag {
  @include tag(inactive);
  margin-left: $global-margin-small;

  &[class*='bx--tag'] {
    margin-left: $global-margin-small;
  }
}

.ContactDataHeading {
  @include text-small;
  color: $color-nightrider;
  display: flex;
  align-items: center;
  margin-top: $global-margin-medium;
  margin-bottom: $global-margin-small;

  svg {
    height: 1.5rem;
    fill: $color-nightrider;
    margin-right: $global-margin-small;
  }
}

.ContactDataTable {
  max-width: 30rem;

  .ContactDataTableHeader {
    display: none;
  }

  tr {
    @include text-small;
    color: $color-nightrider;
    border-bottom: 0 !important;
    height: 2rem;

    td {
      height: 2rem !important;

      &:last-child {
        justify-content: flex-start !important;

        &.ContactSubheading {
          align-items: flex-end;
        }
      }

      &:first-child {
        padding-left: 0;
        align-items: center;
      }

      svg {
        height: 1.25rem;
        margin-top: 0.25rem;
      }
    }
  }
}

.ContactDataCard {
  position: relative;
  padding: $global-padding-medium $global-padding-large;

  &InfoRow {
    display: flex;
    justify-content: space-between;
  }

  .EditContactDataVisibilityButton {
    margin-top: -0.75rem;
    margin-right: -$global-margin-large;

    svg {
      height: 1rem;
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .Greeting {
    height: 4rem;

    [class*='global-content-wrapper'] {
      h1 {
        @include text-semi-small(bold);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet-small) {
  .Greeting {
    height: 3rem;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .InfoTextSmall {
    white-space: wrap;
  }
}

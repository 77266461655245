@import '../../../assets/scss/globals';

.AppHeader {
  font-size: calc(10px + 2vmin);

  [class*='global-content-wrapper'] {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding: $global-padding-large var(--global-content-horizontal-spacing) $global-padding-extra-extra-large
      var(--global-content-horizontal-spacing);
  }

  .HeaderBrand {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    &:focus-visible {
      outline: 2px solid $color-royal-blue;
      outline-offset: 2px;
    }
  }

  .Heading {
    @include text-medium(bold);
    color: $color-lochmara;
    margin-bottom: -0.2rem;
    margin-left: $global-margin-small;
    text-transform: uppercase;
  }

  .TestPage {
    @include text-medium(bold);
    width: 40%;
    text-align: center;
    color: $color-wildberry;
    text-transform: uppercase;

    @media screen and (max-width: $breakpoint-tablet-small) {
      @include text-extra-extra-small(bold);
      padding: $global-padding-small;
    }
  }

  .DropDownMenu {
    height: auto;
  }

  .ButtonGroup {
    button:first-child {
      margin-right: $global-margin-medium;
    }
  }

  .UserBar {
    display: flex;
    justify-content: flex-end;
    background: $color-white-smoke-darker;
    padding: 0 var(--global-content-horizontal-spacing) $global-padding-small;
  }

  .HeaderContentMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: var(--global-content-horizontal-spacing);

    .MenuButton,
    .HomeButton {
      @include text-semi-small(bold);
      padding-left: $global-padding-medium !important;
      padding-right: $global-padding-medium !important;
      color: $color-nightrider;
      text-transform: uppercase;

      > svg {
        height: 2.5rem;
        width: 2.5rem;
      }

      @media screen and (max-width: $breakpoint-tablet-small) {
        @include text-small(bold);

        > svg {
          height: 2rem;
          width: 2rem;
        }
      }
    }

    .MenuButton > svg {
      margin-right: $global-margin-small;
    }

    .HeaderBrand {
      margin: $global-margin-small 0;
      @media screen and (max-width: $breakpoint-tablet-small) {
        svg {
          height: 2rem;
          width: auto;
        }

        .Heading {
          @include text-small(bold);
          margin-left: $global-margin-extra-small;
          margin-bottom: -3px;
        }
      }
    }
  }
}

@import '../../../assets/scss/globals';

.ActionBar {
  background: $color-solitude;
  margin: 0;

  [class*='global-content-wrapper'] {
    padding: $global-padding-small var(--global-content-horizontal-spacing);
    min-height: 3rem;
    align-items: center;
    flex-wrap: wrap;
  }

  &Left,
  &Right {
    display: flex;

    > * {
      max-height: 2rem;
      margin: 0;
    }
  }

  &Left {
    flex: 1 0 250px;

    > button {
      margin-left: -$global-margin-medium;
    }
  }

  &Right {
    flex: 1 0 250px;
    display: flex;
    justify-content: flex-end;

    > button {
      // no spacing between buttons
      margin-right: 0;

      &:last-child {
        // aligns the last buttons icon with the content/table (right edge)
        margin-right: -$global-margin-medium;
      }
    }
  }

  &Center {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;

    + .ActionBarRight {
      margin-left: 0;
    }
  }

  label[class*='bx--toggle-input__label'] > span {
    margin: 0;
  }
}

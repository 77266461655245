@import '../../../../../../assets/scss/globals';

.RadioButtonGroup:not(legend) {
  margin-bottom: $global-margin-small;
  align-self: center;
}

.ItemSection {
  > legend {
    @include text-small(bold);
    color: $color-nightrider;
    margin-bottom: $global-margin-small;

    + * > * {
      margin-top: 0;
      margin-bottom: $global-margin-medium;
    }
  }
}

.ItemGroup {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;

  p {
    @include text-extra-small;
    color: $color-nightrider;
    margin-top: $global-margin-small;
  }

  > * {
    margin-top: $global-margin-medium;
    margin-right: 2.5rem;
    width: 18rem;
    max-width: 18rem;
  }
}

@import 'src/assets/scss/globals';

$toast-min-width: 20rem;
$toast-min-height: 4rem;

@mixin color-notification-icons($icon-color) {
  border-color: $icon-color;

  .bx--toast-notification__icon,
  .bx--inline-notification__icon {
    fill: $icon-color;
    border-radius: 50%;
    background-color: $color-white;
  }
}

.bx--inline-notification {
  max-width: none;
  width: auto;

  background-color: $color-empress;

  &__subtitle {
    text-align: left;
  }
}

.bx--toast-notification {
  min-width: $toast-min-width;
  min-height: 4rem;

  &:first-child {
    margin: 0;
  }

  &__subtitle {
    text-align: left;
  }

  button {
    pointer-events: none;
  }
}

.Toastify__toast-container {
  width: auto;
}

.Toastify__toast {
  padding: 0;
  border-radius: 0;
  min-width: $toast-min-width;

  .Toastify__toast-body {
    padding: 0;
  }

  &-progress,
  .Toastify__progress-bar {
    background: $color-dark-grey;
  }
}

// Icon colors
.bx--toast-notification,
.bx--inline-notification {
  &--success {
    @include color-notification-icons($color-citrus);
  }

  &--error {
    @include color-notification-icons($color-wildberry);
  }

  &--info {
    @include color-notification-icons($color-royal-blue);
  }
}

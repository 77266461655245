@import '../../../assets/scss/globals';

// We use partially px here instead of rem because the map context is in px
.Map {
  width: auto;
  z-index: 1;
  display: flex;
  flex-flow: column nowrap;
  position: relative;

  [class*='leaflet-bar'] {
    border: 0;
    border-radius: unset;
    box-shadow: 1px 1px 1px 0 $color-suva-grey;

    a {
      color: $color-nightrider;

      &[class*='leaflet-disabled'] {
        color: $color-suva-grey;
      }
    }
  }

  a {
    &:focus,
    &:focus-visible {
      box-shadow: inset 0 0 0 2px $color-royal-blue;
      outline: none;
    }
  }

  [class*='leaflet-popup-tip-container'] {
    display: none;
  }

  [class*='leaflet-popup-content-wrapper'] {
    height: 264px;
    width: 232px;
    visibility: hidden;
    border-radius: 0;
  }

  [class*='leaflet-popup-content'] {
    position: relative;
    height: 264px;
    width: 232px;
    visibility: hidden;
    margin: 0;
  }

  [class*='HouseIcon'] {
    border: 2px solid $color-white;
    background-color: $color-lochmara;
    border-radius: 50%;
    box-shadow: 0 0 0 3px $color-lochmara;
    cursor: default;

    svg {
      fill: $color-white;
      width: 22px;
      height: 22px;
      margin: 5px;
    }
  }

  [class*='FavoriteIcon'] {
    border: 1px solid $color-lochmara;
    background-color: $color-lochmara;
    border-radius: 50%;
    box-shadow: 0 0 0 1px $color-lochmara;
    cursor: pointer;

    svg {
      fill: $color-white;
      width: 20px;
      height: auto;
      margin: 7px;
    }

    &:hover {
      border: 1px solid $color-dark-blue;
      background-color: $color-dark-blue;
      box-shadow: 0 0 0 1px $color-dark-blue;
    }
  }

  [class*='ListingIcon'] {
    cursor: pointer;

    &:hover {
      cursor: pointer;
      border: 1px solid $color-dark-blue;
      background-color: $color-dark-blue;
      box-shadow: 0 0 0 1px $color-dark-blue;

      svg {
        fill: $color-white;
      }
    }

    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 3px $color-royal-blue;
      outline: 0;
    }
  }

  [class*='ListingIcon'],
  [class*='TumIcon'] {
    border: 1px solid $color-lochmara;
    background-color: $color-white;
    border-radius: 50%;
    box-shadow: 0 0 0 1px $color-lochmara;
    cursor: default;

    svg {
      fill: $color-lochmara;
      width: 22px;
      height: 22px;
      margin: 6px;
    }
  }

  [class*='PoiIcon'] {
    background-color: $color-lochmara;
    border-radius: 50%;
    cursor: default;

    svg {
      fill: $color-white;
      width: 16px;
      height: 16px;
      margin: 4px;
    }
  }

  [class*='CloseIcon'] {
    border: 1px solid $color-lochmara;
    background-color: $color-lochmara;
    border-radius: 50%;
    box-shadow: 0 0 0 1px $color-lochmara;

    svg {
      fill: $color-white;
      width: 22px;
      height: 22px;
      margin: 6px;
    }

    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 3px $color-royal-blue;
      outline: 0;
    }
  }
}



@import '../../../assets/scss/globals';

.ContentView {
  display: grid;
  grid-template-columns: min-content 1fr;

  // anchor nav is hidden
  @media screen and (max-width: $breakpoint-desktop) {
    display: block;
  }

  > nav {
    position: sticky;
    top: $global-margin-large;
    height: fit-content;
    margin-right: 2rem;
  }

  section {
    width: 100%;
    position: relative;

    + section {
      margin-top: $global-margin-extra-large;
    }

    h2 {
      @include text-semi-large;
      color: $color-nightrider;
      border-bottom: 3px solid $color-dark-grey;
      padding: $global-padding-extra-small $global-padding-small;
      margin-bottom: $global-margin-large;
    }

    .RequiredToolTip {
      @include text-extra-small;
      position: absolute;
      right: 0;
      top: 0;
      color: $color-nightrider;
      margin-top: $global-margin-extra-small;

      &::before {
        content: '*';
        margin-right: $global-margin-extra-small;
        color: $color-lochmara;
      }
    }

    > *:not(:first-child) {
      margin: $global-margin-medium $global-margin-small;
    }

    @media screen and (max-width: $breakpoint-tablet-small) {
      > *:not(:first-child) {
        margin: $global-margin-small 0;
      }
    }

    @media screen and (max-width: $breakpoint-tablet) {
      + section {
        margin-top: $global-margin-medium;
      }
    }
  }
}

.RequireAll label,
.RequireAll legend,
.RequiredFormItem label,
.RequiredFormItem legend,
.RequiredMarker {
  @include required-form-item-label;
}

.RequiredMarker {
  @include text-extra-small;
  margin-top: 1.25rem;
}

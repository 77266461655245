@import '../../../../../assets/scss/globals';

$vertical-input-spacing: $global-margin-extra-large;
$grid-input-spacing: $global-margin-extra-large;
$listings-view-nav-width: 12rem;

.Content {
  padding: $global-padding-large var(--global-content-horizontal-spacing);

  nav {
    width: $listings-view-nav-width;
    max-width: $listings-view-nav-width;
  }

  .ContentViewSection {
    > *:not(:first-child) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.ContactItemGroup {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: $global-margin-medium 2.5rem;

  > * {
    width: 18rem;

    &:last-child {
      margin-top: 0.75rem;
    }
  }
}

.InformationSection {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  column-gap: $grid-input-spacing;

  > *:not(h3) {
    margin-bottom: $global-margin-medium;
  }

  h3 {
    @include text-small(bold);
    color: $color-nightrider;
    grid-column: span 3;
    margin-bottom: $global-margin-small;

    &:not(:first-child) {
      margin-top: $global-margin-small;
    }
  }

  &ListingType:not(legend) {
    grid-column: span 3;

    > fieldset {
      margin-bottom: 0;
    }
  }

  &LivingSpace,
  &Rent {
    width: 100%;
    max-width: 22rem;
    display: flex;

    &Dash {
      display: flex;
      justify-content: center;
      min-width: 2rem;
      color: $color-suva-grey;
      padding-top: 2.25rem;

      + * {
        margin-top: 1.5rem;
      }
    }
  }

  &MovingDates {
    display: flex;
    justify-content: center;

    &Input {
      input[class*='bx--date-picker__input'] {
        width: 10rem !important;
      }
    }

    > :first-child {
      margin-right: $global-margin-medium;
    }
  }

  .InfoText {
    @include text-extra-small;
    color: $color-nightrider;
    margin-top: -$global-margin-small;
  }

  .Checkbox {
    margin-top: $global-margin-small;
  }

  div[class*='bx--multi-select'] {
    max-width: 18rem;
  }

  .TagsSection {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $grid-input-spacing;
    max-width: 72rem;
    grid-column: span 3;

    .MiscTags:not(legend) {
      grid-column: span 2;
      max-height: 16rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 0;

      > legend {
        width: 100%;
      }

      > * {
        flex-grow: 0;
      }
    }
  }

  .TextArea {
    grid-column: span 3;
  }
}

.ResponsibilityFormRow {
  display: flex;

  max-width: 42rem;

  > * {
    flex: 1 1 50%;

    + * {
      margin-left: $global-margin-large;
    }
  }
}

.ResponsibilityTable {
  width: calc(100% - #{$global-margin-medium});
}

/*
 * This file has been created by Ergosign GmbH - All rights reserved - http://www.ergosign.de
 * DO NOT ALTER OR REMOVE THIS COPYRIGHT NOTICE OR THIS FILE HEADER.
 *
 * This file and the code contained in it are subject to the agreed contractual terms and conditions,
 * in particular with regard to resale and publication.
 */

// Font definitions etc.
@mixin text-base-family {
  font-family: Arial, Helvetica, sans-serif;
}

@mixin text-extra-large($weight: null) {
  @include text-base-family;
  font-size: $font-size-extra-large;
  @if $weight == 'bold' {
    font-weight: 600;
  }
}

@mixin text-semi-extra-large($weight: null) {
  @include text-base-family;
  font-size: $font-size-semi-extra-large;
  @if $weight == 'bold' {
    font-weight: 600;
  }
}

@mixin text-large($weight: null) {
  @include text-base-family;
  font-size: $font-size-large;
  @if $weight == 'bold' {
    font-weight: 600;
  }
}

@mixin text-semi-large($weight: null) {
  @include text-base-family;
  font-size: $font-size-semi-large;
  @if $weight == 'bold' {
    font-weight: 600;
  }
}

@mixin text-medium($weight: null) {
  @include text-base-family;
  font-size: $font-size-medium;
  @if $weight == 'bold' {
    font-weight: 600;
  }
}

@mixin text-semi-small($weight: null) {
  @include text-base-family;
  font-size: $font-size-semi-small;
  @if $weight == 'bold' {
    font-weight: 600;
  }
}

@mixin text-small($weight: null) {
  @include text-base-family;
  font-size: $font-size-small;
  font-weight: normal;
  @if $weight == 'bold' {
    font-weight: 600;
  }
}

@mixin text-smaller($weight: null) {
  @include text-base-family;
  font-size: $font-size-smaller;
  @if $weight == 'bold' {
    font-weight: 600;
  }
}

@mixin text-extra-small($weight: null) {
  @include text-base-family;
  font-weight: normal;

  font-size: $font-size-extra-small;
  @if $weight == 'bold' {
    font-weight: 600;
  }
}

@mixin text-extra-extra-small($weight: null) {
  @include text-base-family;
  font-size: $font-size-extra-extra-small;
  @if $weight == 'bold' {
    font-weight: 600;
  }
}

@mixin text-extra-extra-extra-small($weight: null) {
  @include text-base-family;
  font-weight: normal;
  font-size: $font-size-extra-extra-extra-small;
  @if $weight == 'bold' {
    font-weight: 600;
  }
}

@import '../../../assets/scss/globals';

.SearchField {
  display: flex;

  input {
    border-radius: 2px 0 0 2px !important;
  }

  &__lg {
    .SearchButton {
      min-height: 2.5rem;
    }

    label[class*='bx--label'] {
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
      top: -1.5rem;
      clip: unset;
    }
  }
}

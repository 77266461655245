@import '../../../../assets/scss/globals';

.ListingsListFilterContainer {
  margin-bottom: 0;

  .SaveButton {
    position: absolute;
    right: var(--global-content-horizontal-spacing);
    top: $global-margin-large;

    &[class*='bx--btn']:not(.bx--btn--hasIcon):not(.bx--btn--icon-only):not(.bx--btn--sm) {
      padding-right: $global-padding-medium;
      padding-left: $global-padding-medium;
    }

    svg {
      fill: $color-lochmara;
      margin-left: $global-margin-extra-small;
    }
  }

  [class*='global-content-wrapper'] {
    position: relative;

    > div {
      > *:not(button):not(:first-child) {
        flex: 1 1 12rem;
      }

      > *:first-child {
        flex: 1 1 20rem;
      }

      .FilterPlaceholder {
        height: 4rem;
      }
    }
  }
}

.Content {
  @extend %global-content-padding;
  
  .TableActions {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    margin-bottom: var(--global-default-vertical-spacing);
    
    > * {
      max-height: 2.5rem;
    }
    
    &__Map {
      display: flex;
      justify-content: flex-end;
    }
    
    [class*='bx--dropdown__wrapper'] {
      [class='bx--list-box__menu-item__option'] {
        padding: 0.6875rem 1.5rem;
        margin: 0;
      }
    }
  }

  .ViewModeSwitch {
    justify-self: flex-end;

    > [class*='bx--btn']:not([class*='bx--btn--hasIcon']):not([class*='bx--btn--icon-only']):not([class*='bx--btn--sm']) {
      padding-left: $global-padding-small;
      padding-right: $global-padding-small;
    }

    svg {
      width: 1.5rem;
      margin-right: 0;
      fill: $color-white;
    }

    [class*='bx--btn'] {
      height: 100%;
      border-radius: 0;

      > span {
        display: none;
      }
    }

    [class*='bx--btn--secondary'] {
      background-color: $color-solitude-light;
      border-color: $color-solitude-light;

      &:not(:first-child) {
        border: 0;
        border-left: 1px solid $color-lochmara;
      }

      svg {
        fill: $color-lochmara;
      }
    }

    @media screen and (min-width: $breakpoint-desktop) {
      > [class*='bx--btn'] {
        > span {
          display: block;
        }

        &:not([class*='bx--btn--hasIcon']):not([class*='bx--btn--icon-only']):not([class*='bx--btn--sm']) {
          padding-left: $global-padding-medium;
          padding-right: $global-padding-medium;
        }

        svg {
          margin-right: $global-margin-small;
        }
      }
    }
  }

  tbody tr {
    cursor: pointer;

    &:hover {
      background-color: $color-solitude-light;
    }
  }

  .Pagination {
    display: flex;
    justify-content: center;
    margin-top: $global-margin-extra-large;
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .ListingsListFilterContainer {
    [class*='global-content-wrapper'] {
      > div {
        > *:not(button):not(:first-child) {
          flex: 1 1 auto;
        }

        > *:first-child {
          flex: 1 1 auto;
          min-width: 8rem;
        }

        .FilterPlaceholder {
          height: 2.5rem;
        }
      }
    }
  }

  .Map {
    margin-left: calc(var(--global-content-horizontal-spacing) * -1);
    margin-right: calc(var(--global-content-horizontal-spacing) * -1);
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .Content {
    .TableActions {
      [class*='bx--dropdown__wrapper'] {
        [class*='bx--label'] {
          display: none;
        }

        [class='bx--list-box__menu'] {
          left: 0;
          right: auto;
        }
      }
    }
  }
}

@import '../../../assets/scss/globals';

.CommentSection {
  @include card-drop-shadow;
  display: flex;
  flex-direction: column;

  .NewComment {
    display: flex;
    align-items: flex-end;
    padding: $global-padding-medium;
    background: $color-solitude-light;

    [class*='bx--form-item'] [class*='bx--label'] {
      margin-bottom: $global-margin-small;
    }
  }

  .Comments {
    padding: $global-padding-medium;

    > * + * {
      margin-top: $global-margin-medium;
    }
  }

  .ExpandButton {
    align-self: center;
    margin-bottom: $global-margin-small;

    &__expanded {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

/*
 * This file has been created by Ergosign GmbH - All rights reserved - http://www.ergosign.de
 * DO NOT ALTER OR REMOVE THIS COPYRIGHT NOTICE OR THIS FILE HEADER.
 *
 * This file and the code contained in it are subject to the agreed contractual terms and conditions,
 * in particular with regard to resale and publication.
 */

// File for z-index management
$z-index-map-layer: 1;
$z-index-save-bar: 2;
$z-index-sidebar: 6001;
$z-index-favorite-button: 10;

/*
 * This file has been created by Ergosign GmbH - All rights reserved - http://www.ergosign.de
 * DO NOT ALTER OR REMOVE THIS COPYRIGHT NOTICE OR THIS FILE HEADER.
 *
 * This file and the code contained in it are subject to the agreed contractual terms and conditions,
 * in particular with regard to resale and publication.
 */

@mixin placeholder($color: $color-suva-grey) {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  &::placeholder,
  ::placeholder {
    color: $color;
    opacity: 1; /* Firefox */
  }

  /* Microsoft Edge */
  ::-ms-input-placeholder {
    color: $color;
  }
}

/* Skeleton animation adapted from carbon react */
@mixin skeleton {
  position: relative;
  padding: 0;
  background: $color-gainsboro;
  border: 0;
  box-shadow: none;
  pointer-events: none;

  &:hover,
  &:focus,
  &:active {
    border: 0;
    outline: none;
    cursor: default;
  }

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #c6c6c6;
    animation: skeleton 3000ms ease-in-out infinite;
    content: '';
    will-change: transform-origin, transform, opacity;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }
}

@keyframes skeleton {
  0% {
    transform: scaleX(0);
    transform-origin: left;
    opacity: 0.3;
  }

  20% {
    transform: scaleX(1);
    transform-origin: left;
    opacity: 1;
  }

  28% {
    transform: scaleX(1);
    transform-origin: right;
  }

  51% {
    transform: scaleX(0);
    transform-origin: right;
  }

  58% {
    transform: scaleX(0);
    transform-origin: right;
  }

  82% {
    transform: scaleX(1);
    transform-origin: right;
  }

  83% {
    transform: scaleX(1);
    transform-origin: left;
  }

  96% {
    transform: scaleX(0);
    transform-origin: left;
  }

  100% {
    transform: scaleX(0);
    transform-origin: left;
    opacity: 0.3;
  }
}

@import '../../../assets/scss/globals';

.ToolBar {
  background-color: $color-solitude-light;
  width: 100%;
  padding: $global-padding-small;
  display: flex;

  &:focus,
  &:focus-within {
    outline: 2px solid $color-royal-blue;
    outline-offset: -2px;
  }

  button.ToolBarButton {
    padding-left: $global-padding-extra-small !important;
    padding-right: $global-padding-extra-small !important;
  }

  .Spacer {
    margin-left: $global-margin-large;
  }
}

.LinkMenu {
  background-color: $color-white;
  z-index: 9001;

  > div > * + * {
    margin-top: $global-margin-small;
  }
}

.LinkMenuButton {
  > div {
    margin-left: 0 !important;

    svg {
      height: 1.5rem !important;
      width: 1.5rem !important;
      fill: $color-nightrider !important;
    }
  }
}

.DraftButton {
  margin-left: auto;

  &:not(:focus):not(:active):not(:hover) {
    color: $color-nightrider !important;

    svg path {
      fill: $color-lochmara !important;
    }
  }
}

.NotToggled {
  svg {
    path {
      fill: $color-nightrider !important;
    }
  }
}

.AttachmentContainer {
  padding: $global-margin-medium;

  [class*='bx--tag--filter'] {
    @include white-tag();
    cursor: default;

    button {
      &:hover {
        background-color: $color-dark-blue;

        svg path {
          fill: $color-white;
        }
      }

      &:focus {
        box-shadow: 0 0 0 2px $color-royal-blue;
      }
    }
  }
}

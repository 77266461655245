// Spacing
$global-padding-extra-small: 0.25rem; // 4px
$global-padding-semi-extra-small: 0.375rem; // 6px
$global-padding-small: 0.5rem; // 8px
$global-padding-medium: 1rem; // 16px
$global-padding-large: 1.5rem; // 24px
$global-padding-extra-large: 2rem; // 32px
$global-padding-extra-extra-large: 3rem; // 48px

$global-margin-extra-small: 0.25rem; // 4px
$global-margin-small: 0.5rem; // 8px
$global-margin-medium: 1rem; // 16px
$global-margin-large: 1.5rem; // 24px
$global-margin-extra-large: 2rem; // 32px
$global-margin-extra-extra-large: 3rem; // 48px

// Font Sizes
$font-size-extra-large: 2.375rem; // 38px
$font-size-semi-extra-large: 2rem; // 32px
$font-size-large: 1.5rem; // 24px
$font-size-semi-large: 1.25rem; // 20px
$font-size-medium: 1.125rem; // 18px
$font-size-semi-small: 1rem; // 16px
$font-size-small: 0.875rem; // 14px
$font-size-smaller: 0.813rem; // 13px
$font-size-extra-small: 0.75rem; // 12px
$font-size-extra-extra-small: 0.688rem; // 11px
$font-size-extra-extra-extra-small: 0.625rem; // 10px

// Content
$global-max-width-raw: 1280px; // raw max width without padding;
$global-content-padding-horizontal: $global-padding-extra-large;
$global-content-max-width: calc(#{$global-max-width-raw} + 2 * #{$global-content-padding-horizontal});

@import '../../../assets/scss/globals';

.Greeting {
  background: $color-solitude-light;
  height: 4.5rem;
  display: flex;

  [class*='global-content-wrapper'] {
    padding: 0 var(--global-content-horizontal-spacing);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    h1 {
      @include text-semi-large(bold);
      color: $color-nightrider;
    }
  }
}

.SeekerContent {
  padding: $global-padding-medium var(--global-content-horizontal-spacing);

  p {
    @include info-text;

    svg {
      height: 1.25rem;
      fill: $color-lochmara;
      margin: 0 $global-margin-extra-small -4px $global-margin-extra-small;
    }
  }
}

.Tag {
  margin-left: $global-margin-small;
  border: 1px solid transparent;

  &[class*='bx--tag'] {
    margin-left: $global-margin-small;
  }
}

.InactiveTag {
  background-color: $color-white;
  border: 1px solid $color-empress;
}

.SeekerContentView {
  min-height: 18rem;
}

.IconButton {
  @include icon-button();
  margin-top: $global-margin-small !important;
}

@import '../../../assets/scss/globals';

$anchor-navigation-indicator-width: 2px;
$anchor-navigation-item-height: 1.5rem;
$anchor-navigation-item-min-width: 10rem;

.AnchorNavigation {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: $anchor-navigation-item-min-width;

  @media screen and (max-width: $breakpoint-desktop) {
    display: none;
  }

  .AnchorNavigationItem {
    @include text-small;
    color: $color-nightrider;
    position: relative;
    margin-bottom: $global-margin-small;
    height: $anchor-navigation-item-height;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-break: break-word;

    &::before {
      position: absolute;
      content: '';
      width: 0;
      height: 100%;
      transition: width 0.1s ease-out;
    }

    &__selected {
      @include text-small(bold);

      &::before {
        width: $anchor-navigation-indicator-width;
        background-color: $color-lochmara;
      }
    }

    &:hover {
      color: $color-dark-blue;

      &::before {
        width: $anchor-navigation-indicator-width;
        background-color: $color-dark-blue;
      }
    }

    &:focus {
      @extend %focus-outline;
    }

    > span {
      margin-left: 1rem;
    }
  }
}

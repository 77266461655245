@import '../../../assets/scss/globals';

.Maintenance {
  @extend %global-content-padding;
  @include plain-html-style;
  max-width: 49rem;
  margin: 0 auto;
  height: calc(100vh - 668px);
  min-height: 175px;

  h1 {
    @include text-large(bold);
    color: $color-charcoal;
  }
}

@import '../../../assets/scss/globals';

$user-info-dropdown-horizontal-padding-right: 2.5rem;
$user-info-dropdown-horizontal-padding-left: 1.5rem;

$user-info-dropdown-value-padding-vertical: 0.125rem;

.UserInfo {
  [class*='bx--dropdown__wrapper--inline'] {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    grid-gap: 0.25rem;

    [class*='bx--dropdown'] {
      border: 0;
      background-color: transparent;
      color: $color-nightrider;
      height: 1rem;
    }

    [class*='bx--label'] {
      @include text-smaller;
      padding: 0 $user-info-dropdown-horizontal-padding-right 0 $user-info-dropdown-horizontal-padding-left;
      margin-bottom: -0.25rem;
    }

    [class*='bx--list-box__label'] {
      @include text-smaller(bold);
      color: $color-nightrider;

      > svg {
        display: none;
      }
    }

    [class*='bx--list-box__field'] {
      padding: $user-info-dropdown-value-padding-vertical $user-info-dropdown-horizontal-padding-right
        $user-info-dropdown-value-padding-vertical $user-info-dropdown-horizontal-padding-left;
      height: auto;
    }

    [class*='bx--list-box--expanded'] {
      background-color: $color-solitude-light;

      [class*='bx--list-box__menu-icon'] {
        min-width: auto !important;
      }

      [class*='bx--list-box__menu'] {
        min-width: 10rem;
        transition: transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
        overflow: hidden;

        [class*='bx--list-box__menu-item'] {
          [class*='bx--list-box__menu-item__option'] {
            background: url('../../../assets/svg/settings.svg') left center no-repeat;
            padding-left: 2rem;
            margin-left: 0.5rem;
          }

          &:last-child {
            [class*='bx--list-box__menu-item__option'] {
              background: url('../../../assets/svg/logout.svg') left center no-repeat;
            }
          }
        }
      }
    }

    [class*='bx--list-box__menu-icon'] {
      svg {
        fill: $color-lochmara;
      }
    }

    @media screen and (max-width: $breakpoint-desktop) {
      [class*='bx--dropdown'] {
        height: 1.5rem;
      }

      [class*='bx--label'] {
        display: none;
      }

      [class*='bx--list-box__label'] {
        display: flex;
        align-items: center;

        > svg {
          display: inline-block;
          width: 1.5rem;
          margin-left: $global-margin-small;
        }
      }

      [class*='bx--list-box__field'] {
        padding: $user-info-dropdown-value-padding-vertical 1.75rem $user-info-dropdown-value-padding-vertical 1.75rem;
      }

      [class*='bx--list-box__menu-icon'] {
        svg {
          fill: $color-nightrider;
        }
      }
    }
  }
}

.ModalSettings {
  [class*='bx--modal-container'] [class*='bx--modal-content'] [class*='bx--modal-custom-content'] h3 {
    @include text-semi-small(bold);
    margin-bottom: 0;
  }

  h4 {
    @include text-small(bold);
    margin-top: $global-margin-large;
  }

  [class*='bx--form-item']:not(:first-child):not([class*='bx--checkbox-wrapper']) {
    margin-bottom: $global-margin-extra-large;
  }

  [class*='bx--fieldset'] {
    margin-top: -$global-margin-medium;

    [class*='bx--label'] {
      @include text-smaller(bold);
    }
  }

  label[class*='bx--toggle-input__label'] {
    color: $color-nightrider;

    [class*='bx--toggle__switch'] {
      margin-top: $global-margin-small;
    }
  }

  .Settings {
    display: flex;
    flex-flow: row;
  }
}

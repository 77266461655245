@import '../../../../../assets/scss/globals';

$vertical-input-spacing: $global-margin-extra-large;
$grid-input-spacing: $global-margin-extra-large;
$listings-view-nav-width: 12rem;

.Content {
  padding: $global-padding-large;

  nav {
    width: $listings-view-nav-width;
    max-width: $listings-view-nav-width;
  }
}

.ManagementTypeSelection,
.RadioGroupVertical {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * + * {
    margin-top: $global-margin-small;
  }
}

.ManagementTypeLandlord {
  margin-top: $global-margin-large;
}

.ObjectSection {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: $grid-input-spacing;
  align-items: start;

  &ListingType:not(legend) {
    grid-column: span 2;
    margin-bottom: 0;
  }

  &LivingSpace {
    width: 100%;
    max-width: 22rem;
  }

  .Checkbox {
    margin-top: $global-margin-small;
  }

  [class*='bx--fieldset'] {
    [class*='bx--form-item'] {
      max-width: none;
    }
  }

  [class*='bx--form-item'] {
    max-width: 18rem;
  }
}

.AddressSection {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  gap: $grid-input-spacing;
  align-items: start;
  max-width: 50rem;

  .CityLine {
    grid-column: span 2;
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: 2rem;
  }
}

.AddressNotification {
  &[class*='bx--inline-notification'] {
    max-width: 50rem;
  }
}

.MapModal {
  [class*='bx--modal-header'] {
    padding: $global-margin-large $global-margin-large $global-margin-medium;
    margin-bottom: 0;

    h2 {
      @include text-medium(bold);
      color: $color-nightrider;
      margin-bottom: $global-margin-large;
    }

    p {
      @include text-small();
      color: $color-nightrider;
    }
  }

  [class*='bx--modal-content'] {
    border: 0;
    padding-top: 0;
  }
}

.CostSection {
  display: grid;
  grid-template-columns: minmax(12rem, 21rem) 1fr;
  gap: 8rem;
  position: relative;

  .RentOverview {
    position: sticky;
    top: $global-margin-large;
    margin-top: $global-margin-large;
  }

  &Inputs {
    > *:not(:last-child) {
      margin-bottom: $vertical-input-spacing;
    }
  }
}

.TagsSection {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: $grid-input-spacing;
  max-width: 72rem;

  .MiscTags:not(legend) {
    grid-column: span 2;
    max-height: 16rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    > legend {
      width: 100%;
    }

    > * {
      flex-grow: 0;
    }
  }

  .TextArea {
    grid-column: span 2;
  }
}

.InfoText {
  @include text-small;
  color: $color-charcoal;
}

.SeekingDetail {
  margin-left: $global-margin-large;
  margin-top: $global-margin-extra-small;
}

.ErrorMessage {
  @include text-small;
  display: flex;
  color: $color-wildberry;
  align-items: center;
  margin: $global-margin-small $global-margin-medium $global-margin-extra-large $global-margin-small !important;

  > svg {
    height: $font-size-large;
    width: $font-size-large;
    fill: $color-wildberry;
    margin-right: $global-margin-small;
  }
}

.IncidentalCostsTypes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: $global-margin-medium;
}

.OneTimeCosts {
  margin-top: $global-margin-medium;
}

.ParkingSpaceCosts {
  margin-top: $global-margin-small;
}

.CustomIncidentalCostType {
  display: flex;

  > :last-child {
    margin-top: $global-margin-small;
  }
}

.Images {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: $global-padding-medium;

  @media screen and (max-width: $breakpoint-desktop) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (max-width: $breakpoint-tablet) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
